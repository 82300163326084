import React from "react";
import { withSearch } from "@elastic/react-search-ui";
import { Button } from "@material-ui/core";

function LoadMore({ resultsPerPage, setResultsPerPage }) {
  return (
    <div>
       <Button style={{fontSize:"12px"}} onClick={() => setResultsPerPage(resultsPerPage + 10)} color="secondary">
       Cargar Más...
        </Button>
    </div>
  );
}

export default withSearch(({ resultsPerPage, setResultsPerPage }) => ({
resultsPerPage, setResultsPerPage
}))(LoadMore);
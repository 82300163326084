import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Button, Grid } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';


const logoFooter = require('../assets/iso_footer.png');


const temaModi = makeStyles((theme) => ({
    main: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      backgroundColor: '#097CC1',
    },
    img:{
        height: 56,
        width: 167,
        '@media  (max-width:600px)': {
          height: 22,
          width: 67,
        },
        '@media  (min-width: 601px) and (max-width: 768px)': {
          height: 37,
          width: 100,
        },
        '@media  (min-width: 769px) and (max-width: 1200px)': {
          height: 45,
          width: 134,
        },
    },
    contain: {
      alignItems:"center",
    },
    itemstyle: {
      display: 'flex',
    },
    typogtyle: {
      color:'#ffff',
    },
    typogtyle1: {
      color:'#ffff',
      padding: "6px 8px",
    },
    typogtyle2: {
      textAlign: "end",
    },
    iconstyle:{
      marginLeft:"30px", 
      fontSize: "1.3rem",
      height: "1.3em",
      '@media  (max-width:600px)': {
        fontSize: "0.78rem",
      },
    }
  }));
 
export const Footer = () => {
    const classes = temaModi();
  return (    
    <>
    <CssBaseline />
    <footer className={classes.footer}>
      <Container >
      <Grid container className={classes.contain} spacing={1} >
        <Grid item xl={4} lg={4} xs={4} sm={4} md={4} className={classes.typogtyle2}>
            <a title="Procuración del Tesoro" href="https://www.argentina.gob.ar/procuraciondeltesoro">
          <Box
                component="img"
                className={classes.img}
                alt="Procuración del Tesoro"
                src={logoFooter}>
            </Box>
            </a>
        </Grid>
        <Grid item  xl={8} lg={8} xs={8} sm={8} md={8} >    
            <Grid item lg={12} xs={12} sm={12} md={12} className={classes.itemstyle}>        
            <LocationOnOutlinedIcon className={classes.iconstyle} color='secondary'></LocationOnOutlinedIcon>                 
                  <Typography className={classes.typogtyle1} variant="h3">
                  Posadas 1641 - CP: 1112 - Buenos Aires - Argentina -  [+54] +11 4804-3482 / 5385 / 7598
                </Typography>
            </Grid>
            <Grid item  xl={12} lg={12} xs={12} sm={12} md={12} className={classes.itemstyle}>
                <LanguageOutlinedIcon className={classes.iconstyle} color='secondary'></LanguageOutlinedIcon>
                <Button href="https://www.argentina.gob.ar/procuraciondeltesoro" variant="text" color="inherit"> 
                <Typography className={classes.typogtyle} variant="h3">argentina.gob.ar/procuraciondeltesoro
                </Typography>
              </Button>

              </Grid>
              <Grid item  xl={12} lg={12} xs={12} sm={12} md={12} className={classes.itemstyle}> 
              <EmailOutlinedIcon className={classes.iconstyle} color='secondary'></EmailOutlinedIcon>
                <Button href="mailto:biblioteca@ptn.gob.ar" variant="text" color="inherit"> 
                  <Typography className={classes.typogtyle} variant="h3">biblioteca@ptn.gob.ar
                  </Typography>
                </Button>
              </Grid>
        </Grid>        
      </Grid>
      </Container>
    </footer>
  </>
  );
}
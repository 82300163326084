import PropTypes from "prop-types";
import React from "react";
import { Typography } from '@material-ui/core';

function quotesSearchMessage(searchTerm) {
  return (
    <div>
      No se han encontraron resultados para {searchTerm}.
    </div>
  )
}

function PagingInfoView({
  className,
  end,
  searchTerm,
  start,
  totalResults,
  showQuotesSearchText,
  ...rest
}) {
  const cleanSearchTerm = searchTerm.replace(/['"]+/g, '');
  const advancedSearch = searchTerm.includes("BusquedaAvanzada")
  if (advancedSearch) {
    var params = searchTerm.match(/(?:BusquedaAvanzada: tomo )(.*); (?:pagina) (.*); (?:dictamen) (.*); (?:fecha) (.*); (?:tema) (.*)/i)
    var tomo = params[1]
    var pagina = params[2]
    var expediente = params[3]
    var fecha = params[4]
    var tema = params[5]
  }

  return (
    <div style = {{maxWidth:"80%"}}>
    <Typography variant="caption">
    {showQuotesSearchText && quotesSearchMessage(searchTerm)}
    <div {...rest}>
      Mostrando{" "}
      <strong>
        {start} - {end}
      </strong>{" "}
      de <strong>{totalResults}</strong>
      {(advancedSearch && (
        <>
          {" "}
          para la <em>búsqueda avanzada</em> <br/>
          {tomo && ( <span>Tomo: {tomo}<br/></span>  ) }
          {pagina && ( <span>Pagina: {pagina}<br/></span>  ) }
          {expediente && ( <span>Expediente: {expediente}<br/></span>  ) }
          {fecha && ( <span>Año: {fecha}<br/></span>  ) }
          {tema && ( <span>Tema: {tema}<br/></span>  ) }
        </>
       ) )
       || (searchTerm && (
        <>
          {" "}
          para la búsqueda: <em>{showQuotesSearchText ? cleanSearchTerm : searchTerm}</em>
        </>
      ))}
    </div>
    </Typography>
    </div>
  );
}

PagingInfoView.propTypes = {
  end: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  className: PropTypes.string,
  showQuotesSearchText: PropTypes.bool
};

export default PagingInfoView;

import React from "react";
import Popup from "reactjs-popup";
import { Icon } from '@iconify/react';
import helpCircleOutline from '@iconify/icons-mdi/help-circle-outline';
import { Paper, Typography, Fab,makeStyles} from '@material-ui/core';

const example = require('../assets/ayuda_base_imagen.png');

const bodyStyle = {display: "flex", flexFlow: "column nowrap", marginTop: 50, alignItems: "center"}
const fabStyle = {display: "flex", flexFlow: "column nowrap", alignItems: "center"}

const temaModi = makeStyles(theme =>(
  {
    itemStyle: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }   
    },
  }))
  

const triger = (body) => {
  
  const clases = temaModi();
  if (body) {
    return (
      <div className={clases.itemStyle} >
        <div className="body-help-poup" style={ bodyStyle }>
           <Icon icon={helpCircleOutline} className='help-icon' focusable="true"/>
              <Typography variant={body ? "h5" : "h2"} style={{cursor: "pointer"}} focusable="true">
                Ayuda
              </Typography>
          </div>
      </div>
    )
  } else {
    return (      
      <div className={clases.itemStyle} >
        <Fab className="search-help-popup" aria-label="ayuda">
          <div/>
        </Fab>
      </div>
      )
  }
}

export default function HelpPopup(props) {
  return (
    <Popup trigger={triger(props.body)} modal contentStyle={{background: 'none', border: '0'}} >
    {close => (
      <Paper elevation={4} className="result-paper" style={{borderLeft: 'solid 5px', borderColor: "#8B5AF8", padding: '25px' }}>
      <div className="help-modal">
        <button className="close" onClick={close}/>
        <h1> Cómo usar el buscador </h1>
          <div className="content">
          {" "}
            <p><b>Este buscador inteligente permite hacer los siguientes tipos de búsqueda:</b><br/><br/>
            <b>Por tomo y/o página del dictamen:</b><br/>
              Anteponiendo las palabras <b>tomo</b> y/o <b>página</b>.<br/>
              Ejemplo: tomo 251 página 787
              <br/><br/>
            <b>Por leyes referenciadas o involucradas:</b><br/>
              Anteponiendo la palabra <b>ley</b>.<br/>
              Ejemplo: ley 19550
              <br/><br/>
            <b>Por palabras claves:</b> <br/>
              Todos y cada uno de los términos de búsqueda se usaran para encontrar
              coincidencias dentro del texto de la doctrina, del dictamen, de las voces y organismos.<br/>
              Ejemplo: deuda externa
              <br/><br/>
            <b>Por texto exacto:</b><br/>
              Si se encierran términos entre comillas se hará una búsqueda dandole mayor relevancia a
              la coincidencia exacta de esos términos. <br/>
              Ejemplo: deuda externa "nuevos instrumentos"
              <br/><br/>
              Luego de realizar la búsqueda tendrá a disposición distintos filtros para recorrer
              los documentos encontrados. <br/>
              Para obtener los mejores resultados posibles recomendamos evitar consultas compuestas solo por palabras muy comunes como ‘demanda’ y usar en cambio ‘demanda por daño ambiental’ o directamente ‘daño ambiental’.
            </p>
            <h1> Cómo leer los resultados</h1>
            <img className="help-example" src={example} alt="Ejemplo de resultado" />
          </div>
      </div>
      </Paper>
    )}
    </Popup>
  );
}

import React from 'react';
import {makeStyles, Box} from '@material-ui/core'; 

const logo = require('../assets/iso_ptnn.png');

const temaModip = makeStyles(theme =>(
    {
        img:{
          height: 71,
          width: 213,
            '@media  (max-width:600px)': {
              height: 28,
              width: 85,
            },
            '@media  (min-width: 601px) and (max-width: 800px)': {
              height: 28,
              width: 85,
            },
            '@media  (min-width: 801px) and (max-width: 1200px)': {
              height: 57,
              width: 170,
            },
        },
    }))

export default function Headerp() {
    const clasesp = temaModip();
  
    return (
        <Box
                  className={clasesp.img}   
                  component="img"            
                  alt="Logo de la Procuración del Tesoro"
                  src={logo}>
              </Box>
        )
  }
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import { Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import sortIcon from '@iconify/icons-mdi/sort';
import { appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";



const setDefaultStyle = {
  option: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
  singleValue: provided => {
    // Pulling out CSS that we don't want
    // eslint-disable-next-line no-unused-vars
    const { position, top, transform, maxWidth, ...rest } = provided;
    return { ...rest, lineHeight: 1,
            margin: 0,
            color: '#097CC1',
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "13pt",
            '@media  (max-width: 600px)': {
                fontSize: "9pt",
              },
              '@media  (min-width: 601px) and (max-width: 768px)': {
                fontSize: "11pt",
              },
            overflow:"visible" }; 
  },
  valueContainer: provided => ({ ...provided, paddingRight: 0 }),
  menu: provided => ({ ...provided,
                      color: '#097CC1',
                      fontFamily: "'Roboto', sans-serif",
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "13pt",
                      '@media  (max-width: 600px)': {
                        fontSize: "9pt",
                      },
                      '@media  (min-width: 601px) and (max-width: 768px)': {
                        fontSize: "11pt",
                      },
                     }),
  control: provided => ({ ...provided, border: 0, backgroundColor: "#FFFFFF", boxShadow: "none", cursor: "pointer"}),
};

function Sorting({ className, label, onChange, options, value, ...rest }) {
  const selectedValue = value;
  const selectedOption = selectedValue
    ? options.find(option => option.value === selectedValue)
    : null;

  return (
    <div className={appendClassName("sui-sorting", className)} {...rest}>
      {label && <div className="sui-sorting__label">
        <Typography variant="h6" style={{display: "flex", alignItems: "center", textTransform: "none"}}>
          <Icon icon={sortIcon} style={{color: "#C2CFE0", fontSize: "19.5px", marginRight: "5px"}}/> {label}
        </Typography>
      </div>}
      <Select
        className="sui-select"
        classNamePrefix="sui-select"
        value={selectedOption}
        onChange={o => onChange(o.value)}
        options={options}
        isSearchable={false}
        styles={setDefaultStyle}
      />
    </div>
  );
}

Sorting.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  value: PropTypes.string
};

export default Sorting;

import PropTypes from "prop-types";
import React from "react";
import { Typography } from '@material-ui/core';
import { FacetValue } from "@elastic/react-search-ui-views/lib/types";
import { getFilterValueDisplay, appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";
import ExpandMore from "@material-ui/icons/ExpandMore";


function MultiCheckboxFacetP({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder
}) {

  return (
    <fieldset className={appendClassName("sui-facet", className)}>
      {showSearch && (
        <div className="sui-facet-search">
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder={searchPlaceholder || "Search"}
            onChange={e => {
              onSearch(e.target.value);
            }}
            aria-labelledby={`${label}-label`}
            style={{height:"35px",
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: "11pt",
                    color: "#707683"}}
          />
        </div>
      )}

      <div className="sui-multi-checkbox-facet">
        {options.length < 1 && <div>No se encontraron coincidencias</div>}
        {options.map(option => {
        const checked = option.selected;
        var etiqueta = option.value;
        etiqueta = etiqueta.replace('Ministerio ', 'M.');
        etiqueta = etiqueta.replace('Secretaría ', 'S.');
        etiqueta = etiqueta.replace('Administración ', 'Adm.');
        etiqueta = etiqueta.replace('Dirección ', 'Dir.');
        etiqueta = etiqueta.replace('Procuración del Tesoro de la Nación', 'PTN');
        etiqueta = etiqueta.replace('Nacional ', 'Nac.');
        etiqueta = etiqueta.replace('Nacionales', 'Nac.');
        etiqueta = etiqueta.replace('Instituto ', 'Inst.');
        etiqueta = etiqueta.replace('Planificación ', 'Planif.');
        etiqueta = etiqueta.replace('Seguridad ', 'Seg.');
        etiqueta = etiqueta.replace('Economía ', 'Econ.');
        etiqueta = etiqueta.replace('Servicios', 'Serv.');
        etiqueta = etiqueta.replace('Públicos', 'Pub.');
        etiqueta = etiqueta.replace('Jubilados', 'Jub.');
        etiqueta = etiqueta.replace('Pensionados', 'Pens.');
        etiqueta = etiqueta.replace('Servicio ', 'Serv.');
          return (
            <Typography variant="h4" >
            <label
              key={`${getFilterValueDisplay(option.value)}`}
              htmlFor={`example_facet_${label}${getFilterValueDisplay(
                etiqueta
              )}`}
              className="sui-multi-checkbox-facet__option-label"
            >
              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                
                <span className="sui-multi-checkbox-facet__input-text">
                {option.value === 'historico' ? "anteriores a 1999" :
                    option.value === 'dictamenes' ? "posteriores a 1999"
                    :
                     getFilterValueDisplay(etiqueta)}
                </span>
              </div>
              <span className="sui-multi-checkbox-facet__option-count">
              <Typography variant="caption" color={checked ? "secondary" : "primary"}>
                {option.count.toLocaleString("es")}
              </Typography>
              </span>

              <input
                  id={`example_facet_${label}${getFilterValueDisplay(
                    etiqueta
                  )}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={checked}
                  onChange={() =>
                    checked ? onRemove(option.value) : onSelect(option.value)
                  }
                  style={{transform:"scale(1.1)"}}
                />
            </label>
            </Typography>
          );
        })}
      </div>

      {showMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onMoreClick}
          aria-label="Mostrar más opciones"       >
              <ExpandMore fontSize="small"/>
          
        </button>
      )}
    </fieldset>
  );
}

MultiCheckboxFacetP.propTypes = {
  label: PropTypes.string.isRequired,
  onMoreClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(FacetValue).isRequired,
  showMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  showSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string
};

export default MultiCheckboxFacetP;

import axios from 'axios';
import { fetchToken } from '../components/auth';

export default async function addDictamen(formData) {
    let token = fetchToken();
    const response = await axios({
        method: 'post',
        url: `${window._env_.REACT_APP_API_URL}/admin/dictamen`,
        data: JSON.parse(JSON.stringify(formData)), // JSON.parse()
        headers: {"Access-Control-Allow-Origin": "*", "Content-Type":"application/json", "Authorization": `Bearer ${token}`}
    });
    return response;
}

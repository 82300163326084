import axios from 'axios';

export default async function runRequest(searchProps, historicos = false, solo_historicos = false) {
  // const response = await axios.post(window._env_.REACT_APP_API_URL + `/search`, JSON.stringify(searchProps.body))
  const response = await axios({
  method: 'post',
  url: `${window._env_.REACT_APP_API_URL}/search?historico=${historicos}&solo_historico=${solo_historicos}`,
  data: JSON.stringify(searchProps.body),
  //headers: {"Access-Control-Allow-Origin": "*", "Content-Type":"application/json"}
  headers: {"Content-Type":"application/json"}
});
  response.data.searchTerm = searchProps.searchTerm;
  return response.data;
}

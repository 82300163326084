import buildRequestFilter from "./buildRequestFilter";

var VOCES_BOOST = window._env_.REACT_APP_VOCES_BOOST
var ORGANISMO_BOOST = window._env_.REACT_APP_ORGANISMO_BOOST

function buildFrom(current, resultsPerPage) {
  if (!current || !resultsPerPage) return;
  return (current - 1) * resultsPerPage;
}

function buildSort(sortDirection, sortField) {
  if (sortDirection && sortField) {
    return [{ [`${sortField}`]: sortDirection }];
  }
}

function buildMatchPhraseVoices(quotes_match) {
  return {"match_phrase": {
            "voces": {
              "query": quotes_match.replace(/['"]+/g, ''),
              "boost": VOCES_BOOST * 20
            }
          }}
}

function buildMatchPhraseContent(quotes_match) {
  return {"match_phrase": {
    'attachments.attachment.content': {
              "query": quotes_match.replace(/['"]+/g, ''),
              "boost": VOCES_BOOST * 20
            }
          }}
}

function buildTomoQuery(searchTerm) {
  var value_tomo = searchTerm.replace(' ', '').trim();
  var clean_tomo_match = value_tomo.match(/0*(.*)/i);
  var longitud = clean_tomo_match[1].length;
  var tomoQuery = {
    regexp: {
      tomo: {
        value: (longitud < 3) ? `0{0,${3-longitud}}${clean_tomo_match[1]}` : clean_tomo_match[1],
        case_insensitive: true,
      }
    }
  }
  return tomoQuery;
}

function buildPaginaQuery(searchTerm) {
  var value_pagina = searchTerm.replace(' ', '').trim();
  var clean_pagina_match = value_pagina.match(/0*(.*)/i);
  var longitud = clean_pagina_match[1].length;
  var paginaQuery = {
    regexp: {
      pagina: {
        value: (longitud < 3) ? `0{0,${3-longitud}}${clean_pagina_match[1]}` : clean_pagina_match[1],
        case_insensitive: true,
      }
    }
  }
  return paginaQuery;
}

function buildMatch(searchTerm) {
  // if(!searchTerm) return { match_all: {} };
  var tomo_match = searchTerm.match(/tomo (\d+\s*(bis|ter|quater|quinquies|sexies|septies|octies|novies|decies|undecies|duodecies|terdecies|quaterdecies|quindecies|sexdecies)*)/i)
  var pagina_match = searchTerm.match(/(pagina|página|pag|pag.) (\d+\s*(bis|ter|quater|quinquies|sexies|septies|octies|novies|decies|undecies|duodecies|terdecies|quaterdecies|quindecies|sexdecies)*)/i)
  var ley_match = searchTerm.match(/ley\s*n?°?\s*([0-9]+\.[0-9]+|[0-9]+)/i)
  var decreto_match = searchTerm.match(/decreto\s*n?°?\s*([0-9]+\.[0-9]+|[0-9]+[\/]{0,1}[0-9]+)/i)
  if (tomo_match) {
    var tomoQuery = buildTomoQuery(tomo_match[1]);
  }
  if (pagina_match) {
    var paginaQuery = buildPaginaQuery(pagina_match[2]);
  }
  if (ley_match) {
    var ley = ley_match[1];
    ley = ley.replace('.', '');
  }
  if (decreto_match) {
    var decreto = decreto_match[1];
    decreto = decreto.replace('.', '');
  }

  var quotes_match = searchTerm.match(/"([^"]*)"/ig) || []
  var quotes_match_queries_voices = quotes_match.map(buildMatchPhraseVoices)
  var quotes_match_queries_content = quotes_match.map(buildMatchPhraseContent)
  var matchQuery = (tomoQuery && paginaQuery) ? [tomoQuery, paginaQuery] : (tomoQuery ? [tomoQuery] : (paginaQuery ? [paginaQuery] :
     [{dis_max: {
        queries: [
          {match: {
            voces: {query: searchTerm, operator: 'or',
                    minimum_should_match: '75%', boost: VOCES_BOOST}
          }},
          {match: {
            organismo: {query: searchTerm, operator: 'or',
                        minimum_should_match: '75%', boost: ORGANISMO_BOOST}
          }},
          {match: {
            'attachments.attachment.content':
              {query: searchTerm, operator: 'and'}
          }},
          {match: {
            'array_leyes':
            {query: (ley || " "), boost: 10*10}
          }},
          {match: {
            'array_decretos':
            {query: (decreto || " "), boost: 10*10}
          }}
        ].concat(quotes_match_queries_content, quotes_match_queries_voices),
            tie_breaker: 0.3
        }
    }]))

    return searchTerm ? matchQuery : { match_all: {} };
}

function getAnioFilter(filters) {
  var values = []
  filters.forEach((item, i) => {
    if (["anio"].includes(item.field)) {values = item.values}
    return;
  });
  return values
}

function buildAdvancedSearch(searchTerm) {
  var params = searchTerm.match(/(?:BusquedaAvanzada: tomo )(.*); (?:pagina) (.*); (?:dictamen) (.*); (?:fecha) (.*); (?:tema) (.*)/i)
  var tomo = params[1]
  var pagina = params[2]
  var expediente = params[3]
  var tema = params[5]
  var queries = []
  
  if (tomo) {
    var tomoQuery = buildTomoQuery(tomo); 
    queries = queries.concat(tomoQuery);
  }
  if (pagina) {
    var paginaQuery = buildPaginaQuery(pagina); 
    queries = queries.concat(paginaQuery);
  }
  if (expediente) {
    var expedienteQuery = [{term: {numero: expediente}}]; 
    queries = queries.concat(expedienteQuery);
  }

  if (tema) {
    var temaQuery = {match: {
                      'attachments.attachment.content':
                        {query: tema, operator: 'and'}
                    }};
    queries = queries.concat(temaQuery);
  }
  var matchQuery = [{bool:{must:queries}}]
  
  return matchQuery
}

function advancedSearchFilter(searchTerm) {
  var params = searchTerm.match(/(?:BusquedaAvanzada: tomo )(.*); (?:pagina) (.*); (?:dictamen) (.*); (?:fecha) (.*); (?:tema) (.*)/i)
  var anio = params[4]

  if (!/^\d{4}$/.test(anio)) {return undefined}
  var filter = anio ? {
    range: { "fecha": {
                "gte": `${anio}-01-01`,
                "lte": `${anio}-12-31`
            }}
  } : undefined

  return filter;
}

/*

  Converts current application state to an Elasticsearch request.

  When implementing an onSearch Handler in Search UI, the handler needs to take the
  current state of the application and convert it to an API request.

  For instance, there is a "current" property in the application state that you receive
  in this handler. The "current" property represents the current page in pagination. This
  method converts our "current" property to Elasticsearch's "from" parameter.

  This "current" property is a "page" offset, while Elasticsearch's "from" parameter
  is a "item" offset. In other words, for a set of 100 results and a page size
  of 10, if our "current" value is "4", then the equivalent Elasticsearch "from" value
  would be "40". This method does that conversion.

  We then do similar things for searchTerm, filters, sort, etc.
*/
export default function buildRequest(state, clearQuotes = false) {
  const {
    current,
    filters,
    resultsPerPage,
    searchTerm,
    sortDirection,
    sortField
  } = state;
  var advancedSearch = searchTerm.includes("BusquedaAvanzada");
  if (clearQuotes) {var cleanSearchTerm = searchTerm.replace(/['"]+/g, '')}
  const sort = buildSort(sortDirection, sortField);
  const match = clearQuotes ? buildMatch(cleanSearchTerm) : (advancedSearch ? buildAdvancedSearch(searchTerm) : buildMatch(searchTerm));
  const size = resultsPerPage;
  const from = buildFrom(current, resultsPerPage);
  var filter = buildRequestFilter(filters, searchTerm);

  if (advancedSearch) {
    var advancedFilter = advancedSearchFilter(searchTerm);
    if (advancedFilter) {
      filter = filter ? filter.concat([advancedFilter]) : [advancedFilter];
    }
  }

  const body = {
    // Static query Configuration
    // --------------------------
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-highlighting.html
    highlight: {
      fragment_size: 200,
      number_of_fragments: 5,
      fields: {
        voces: {},
        'attachments.attachment.content': {},
        organismo: {}
      }
      },
    //https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-source-filtering.html#search-request-source-filtering
    aggs: {
      organismo: { terms: { field: "organismo.keyword", size: 100 } },
      voces: { terms: { field: "voces.keyword", size: 500 } },
      tomo: {
        terms: {field: "tomo.keyword" },
        aggs: {
          pagina: { terms: {field: "pagina.keyword"} }
        }
      },
      anio: {
        terms: {script: {source: "if (doc.containsKey('fecha')) { doc['fecha'].value.getYear() }", lang: "painless"}, size: 3000},
        aggs: {
          mes: {terms: {script: {source: "if (doc.containsKey('fecha')) { doc['fecha'].value.getMonth() }", lang: "painless"}}}
        }
      },
      mes: {
        date_histogram: {
          field: "fecha",
          calendar_interval: "month",
          order: {
            _key: "desc"
          }
        }
      },
      indices: {
        terms: { field: "_index" }
      }
    },

    // Dynamic values based on current Search UI state
    // --------------------------
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/full-text-queries.html
    query: {
      bool: {
        must: match,
        ...(filter && { filter })
      }
    },
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-sort.html
    ...(sort && { sort }),
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-from-size.html
    ...(size && { size }),
    ...(from && { from })
  };
  const searchProps = {
    searchTerm: searchTerm,
    body: body,
    anioFilter: getAnioFilter(filters) || []
  }
  // return body;
  return searchProps;
}

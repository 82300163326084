import React from "react";
import CustomSlider from "./customSlider";

export default function RenderSlider(props) {
  var [values, setValue] = React.useState(props.value);
// Esto es para que la primera vez que carga se haga efectivo el filtro de los últimos anios
  // React.useEffect(() => {
  //   props.onChange(values[0])
  //   if (values[1]) { props.addFilter(values[1]) }
  // }, [])

// Cada vez que cambia el valor de value se actualiza en el estado
  React.useEffect(() => {
      setValue(props.value);
  }, [props.value])

  const handleChange = (event, newValue) => {    
    setValue(newValue);
    props.onChange(newValue[0])
    if (newValue[1]) { props.addFilter(newValue[1]) }
  };

  const maximum = props.marks[props.marks.length - 1].value
  const minimum = props.marks[0].value

  const updateValue = (event, v) => {
                        setValue(v);
                      }

  return (<CustomSlider
            value={values}
            onChangeCommitted={handleChange}
            marks={props.marks}
            max={maximum}
            min={minimum}
            onChange={updateValue}
            label={props.label}
            mini={props.mini} />)
}

import axios from 'axios';
import { fetchToken } from '../components/auth';

export default async function updateDictamen(id, formData) {
    let token = fetchToken();
    const response = await axios({
        method: 'put',
        url: `${window._env_.REACT_APP_API_URL}/admin/dictamen/${id}`,
        data: JSON.parse(JSON.stringify(formData)),
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
    });
}

import { width } from "@fortawesome/free-solid-svg-icons/faBars";
import React , {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function Rangofecha(props) {
var [values, setValue] = React.useState(props.value);
var [startDate, setStartDate] = useState(new Date(props.value[0]+"-01-02"));
// Esto es para que la primera vez que carga se haga efectivo el filtro de los últimos anios
/*
  React.useEffect(() => {
    props.onChange(values[0])
    if (values[1]) { props.addFilter(values[1]) }
  }, [])
*/
// Cada vez que cambia el valor de value se actualiza en el estado
  React.useEffect(() => {
      setValue(props.value);
  }, [props.value])

  const handleChange = (date) => {
    if (date==null ) {return}
    if (date.getFullYear()<9999)
    {
      setStartDate(date);
      setValue(values[0]=date.getFullYear());
      setValue(values);
      props.onChange(values[0])
      if (values[1]) { props.addFilter(values[1]) }
    }else{
      return
    }
  };
  const minimum = props.marks[0].value

return (
    <DatePicker selected={startDate} onChange={handleChange} dateFormat="yyyy" showYearPicker/>
    ) 
}

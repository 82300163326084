/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';

const PtnPopup = (props) => {
  const { children } = props;
  return (
    <Popup {...props}>
      {children}
    </Popup>
  );
};
export default PtnPopup;

PtnPopup.propTypes = {
  children: PropTypes.element.isRequired
};

import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { RequireToken } from './auth';

const DeleteDictamenComponent = (params) => {
    const id = params.id;
    const type = params.type;
    const [token, setToken] = useState('');
    const [open, setOpen] = useState(false);

    const handleDelete = useCallback(async () => {
        params.onSubmit(id);
        params.onSearch("");
        handleClose(false);
    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    return (
        <div>
            <RequireToken>
                <Button variant="outlined" color="error" size="small" onClick={handleOpen}>
                    {params.label}
                </Button>
                <Dialog open={open}
                    onClose={handleClose}>
                    <DialogTitle>
                        Confirmar eliminar dictamen
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {params.mensaje}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>Cancelar</Button>
                        <Button onClick={handleDelete}>Eliminar</Button>
                    </DialogActions>
                </Dialog>
            </RequireToken>
        </div>
    );
};

export default DeleteDictamenComponent;
import React from 'react';
import PropTypes from 'prop-types';
import trimText from '../helpers/trimText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Typography } from '@material-ui/core';
import DoctrinaContent from './doctrinaContent';

function getTrimText(props) {
	let args = [
		props.text,
		props.min,
		props.ideal,
		props.max
	];

	return trimText(...args);
}

export default class ReadMore extends React.Component {
	constructor(props){
		super(props);

		const [primaryText, secondaryText] = getTrimText(this.props);
		this.state = { displaySecondary: false, primaryText , secondaryText, readMoreText: this.props.readMoreText, readLessText: this.props.readLessText };
	}

	setStatus() {
		let display = !this.state.displaySecondary;
		this.setState({displaySecondary: display});
	}

	componentDidUpdate(prevProps) {
  	// Uso tipico (no olvides de comparar los props):
	  if (this.props.text !== prevProps.text) {
	    var [primaryText, secondaryText] = getTrimText(this.props);
			this.setState({
				primaryText: primaryText,
				secondaryText: secondaryText
			})
	  }
	}

	render() {
		let displayText;
		if (!this.state.secondaryText) {
			displayText = (
			<Typography variant="body1">
			<div className="display-text-group">
				<DoctrinaContent text={this.state.primaryText + this.state.secondaryText}></DoctrinaContent>
			</div>
		</Typography>);
		}
		else if (this.state.displaySecondary) {
			displayText = (
			<div className="display-text-group">
				<Typography variant="body1">
			<DoctrinaContent text={this.state.primaryText + this.state.secondaryText}></DoctrinaContent>
				<div className="read-more-button"
						 onClick={this.setStatus.bind(this)}>
					<Typography style={{textAlign: "right"}} f variant="body2">
	             {this.state.readLessText + ""}
	             <ExpandLessIcon fontSize="large"/>
	 		 	 	</Typography>
				</div>
				</Typography>
			</div>
		);
		} else {
			displayText = (
				<div className="display-text-group">
					<Typography variant="body1">
			<DoctrinaContent text={this.state.primaryText}></DoctrinaContent>
				<div className="read-more-button"
						 onClick={this.setStatus.bind(this)}>
				<Typography style={{textAlign: "right"}}  variant="body2">
              {this.state.readMoreText + " "}
              <ExpandMoreIcon fontSize="large"/>
						</Typography>
						</div>
						</Typography>
			</div>
			);
		}

		return displayText;
	}
}

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  min: PropTypes.number,
  ideal: PropTypes.number,
  max: PropTypes.number,
	readMoreText: PropTypes.string,
  readLessText: PropTypes.string,
};

ReadMore.defaultProps = {
  readMoreText: "read more",
  readLessText: "read less",
};

function getValueFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: bucket.key_as_string || bucket.key,
          count: bucket.doc_count
        }))
      }
    ];
  }
}

function getYearFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: bucket.key_as_string || parseInt(bucket.key),
          count: bucket.doc_count
        }))
      }
    ];
  }
}

function getMonthFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[fieldName].buckets.map(bucket => ({
          value: bucket.key_as_string || parseInt(bucket.key),
          count: bucket.doc_count
        }))
      }
    ];
  }
}

export default function buildStateFacets(aggregations) {
  const voces = getValueFacet(aggregations, "voces");
  const organismo = getValueFacet(
    aggregations,
    "organismo"
  );
  const anio = getYearFacet(aggregations, "anio");
  const mes = getMonthFacet(aggregations, "mes");
  const indices = getValueFacet(aggregations, "indices");
  // const acres = getRangeFacet(aggregations, "acres");

  const facets = {
    ...(anio && { anio }),
    ...(voces && { voces }),
    ...(organismo && { organismo }),
    ...(mes && { mes }),
    ...(indices && { indices })
  };

  if (Object.keys(facets).length > 0) {
    return facets;
  }
}

import PropTypes from "prop-types";
import React from "react";
import { Typography, makeStyles } from '@material-ui/core';
import { Icon } from '@iconify/react';
import sortIcon from '@iconify/icons-mdi/sort';
import { appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";


import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      marginLeft:"80px",
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
  });

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  
  
function SortingP({ className, label, onChange, options, value,versort, ...rest }) {
const selectedValue = value;
const selectedOption = selectedValue
  ? options.find(option => option.value === selectedValue)
  : null;

  
  return (
    <div className={appendClassName("sui-sorting", className)} {...rest}>
      {label && <div className="sui-sorting__label">
        <Typography variant="h6" style={{display: "flex", alignItems: "center", textTransform: "none"}}>
          <Icon icon={sortIcon} style={{color: "#C2CFE0", fontSize: "19.5px", marginRight: "5px"}}/> {label}
        </Typography>
      </div>}
      <FormControl component="fieldset">
        <FormLabel component="legend"></FormLabel>
          <RadioGroup defaultValue={selectedValue} onChange={event => {versort(event.target.value); (onChange(event.target.value))}} >
            <FormControlLabel value={options[0].value} control={<StyledRadio />} label={options[0].label} labelPlacement="start"/>
            <FormControlLabel value={options[1].value} control={<StyledRadio />} label={options[1].label} labelPlacement="start"/>
            <FormControlLabel value={options[2].value} control={<StyledRadio />} label={options[2].label} labelPlacement="start"/>
          </RadioGroup>
    </FormControl>
    </div>
  );
}

SortingP.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  value: PropTypes.string,
  versort: PropTypes.func.isRequired,

};

export default SortingP;

import React, {useState,useRef} from "react";
// import Math;
// npm run build

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  WithSearch,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting
} from "@elastic/react-search-ui";

import {ResultView, PagingView, PagingInfoView, SliderRangeSelectFacet,SliderRangeSelectFacetP,
        MultiCheckboxFacet, ResultsPerPageView, SortingView, InputView, SortingViewP, MultiCheckboxFacetP} from './views';
import PagingInfoViewP from "./views/PagingInfoViewP";
import { Layout } from '@elastic/react-search-ui-views';
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Button, LinearProgress,Hidden,Container, Grid} from '@material-ui/core';
import { Typography , Box} from '@material-ui/core';
import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";
import applyIndexFaceting from "./applyIndexFaceting";
import buildState from "./buildState";
import HelpPopup from './views/HelpPopup';
import AdvancedSearchComponent from "./components/advancedSearchComponent.js";
import { Header } from "./views/Header";
// Acordeón - Filtros Mobile
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// 
import Clearfilters from "./components/Clearfilters";
import ClearfiltersWeb from "./components/ClearfiltersWeb";
import LoadMore from "./views/LoadMore";

import ScrollTop from  "./views/ScrollTop";
import Fab from '@material-ui/core/Fab';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';

import { Icon } from '@iconify/react';
import baselineDateRange from '@iconify/icons-ic/filter-list.js';

var emptyQuotesSearch = false;
var tomoSearch = false;
var anioFilter = [];
// 2024 
var anioFilterInicial = [];
// 2024 


var sidebarShadow = {
  paddingLeft: "24px !important",
  boxShadow: "3px 0 2px -2px rgba(0,0,0,0.2)",
  padding: "32px 32px 0 0"
}


const config = {
  searching: "",
  initialState: {
            resultsPerPage: 10
        },
  debug: false,
  hasA11yNotifications: true,
  onResultClick: () => {
    /* Not implemented */
  },
  onAutocompleteResultClick: () => {
    /* Not implemented */
  },
  onAutocomplete: async ({ searchTerm }) => {
    // this.searching = searchTerm;
    // const requestBody = buildRequest({ searchTerm });
    // const json = await runRequest(requestBody);
    // const state = buildState(json);
    // return {
    //   autocompletedResults: state.results
    // };
    return {}
  },
  onSearch: async state => {
    if (state.searchTerm === "") {return config.initialState}
    emptyQuotesSearch = false
    tomoSearch = false
    anioFilter = [];
    var ano = (new Date(). getFullYear())
    anioFilterInicial = [1942,ano];
    const { resultsPerPage } = state;
    const requestBody = buildRequest(state);
    anioFilter = requestBody.anioFilter
    // Note that this could be optimized by running all of these requests
    // at the same time. Kept simple here for clarity.
    let historico = true;
    let soloHistorico = false;
    const filtroIndices = state.filters.filter(filtro => (filtro.field === "indices"));
    if (filtroIndices &&
      filtroIndices.length > 0 &&
      filtroIndices[0].values &&
      filtroIndices[0].values.length > 0) {
        const filtros = filtroIndices[0].values;
        historico = filtros.includes("historico");
        soloHistorico = !filtros.includes("dictamenes");
    } else {
      historico = true;
      soloHistorico = false;
    }
    const responseJson = await runRequest(requestBody, historico, soloHistorico);
    const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
      responseJson,
      state,
      ["indices"]
    );
    const responseJsonIndexFacetCounts = await applyIndexFaceting(
      responseJsonWithDisjunctiveFacetCounts,
      state,
      ["indices"]
    );
    if (state.searchTerm.includes('"')) {
      const requestBodyWithoutQuotes = buildRequest(state, true);
      const responseJsonWithoutQuotes = await runRequest(requestBodyWithoutQuotes, historico, soloHistorico);
      const newState = buildState(responseJsonIndexFacetCounts, resultsPerPage,
                        anioFilter, responseJsonWithoutQuotes);
      emptyQuotesSearch = newState.emptyQuotesSearch
      tomoSearch = newState.tomoSearch
      return newState      
    }
    else {
      const newState = buildState(responseJsonIndexFacetCounts, resultsPerPage, anioFilter);
      tomoSearch = newState.tomoSearch
      return newState;
    }
  }
};


export default function Buscador() {
  const [versearch, setversearh]=useState(false);
    // Para saber el orden 
  const [verssort, setsort]=useState("Más relevantes");

  const changeSort = (valuesort) =>  {
      switch (valuesort) {
        case "|||":
          setsort("Más relevantes");
          break; 
          
          case "fecha|||asc":
          setsort("Más antiguos");
          break; 
         
          case "fecha|||desc":
          setsort("Más recientes");
          break; 

          default:
          setsort("Más relevantes");
      }
    };
    // Acordeón
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
      setOpen(true);
      setScroll(scrollType);
    };

    const handleClose = () => {
      setOpen(false);
    };

  const inputBus = useRef(null);

 // Para que vuelva a la búsqueda
  const volverinicio = () =>  {
      inputBus.current.focus(); 
    };

  return (
    <SearchProvider config={config}> 
    <Hidden mdUp>
      <Header volverini={volverinicio} dondeesta={versearch} />
     </Hidden>
      <WithSearch mapContextToProps={({ wasSearched, isLoading, tomoSearch, setSearchTerm }) => ({ wasSearched, isLoading, tomoSearch, setSearchTerm })}>
        {({ wasSearched, isLoading, tomoSearch, setSearchTerm }) => (
          <div className="App">
            <ErrorBoundary>
            <div style={{ background: (wasSearched ? "#F5F6F8" : "#FFFFFF") }}>
            {wasSearched &&

            <HelpPopup/>
          }
            {!wasSearched &&
            
              <div className="body-home">
                <SearchBox
                className="sui-search-box_two"
                view = {InputView}
                inputView={({ getInputProps, getButtonProps }) => (
                    <>
                      <div className="sui-search-box__wrapper_two">
                        <Typography variant="h6">
                          <input
                            {...getInputProps({
                              placeholder: "Ingresá el texto de búsqueda. Ej: tomo 251 pagina 787 / ley 26076 / deuda externa"
                            })}
                          />
                        </Typography>
                      </div>
                        <input
                          {...getButtonProps({
                            value: "Buscar"
                          })}
                        />
                    </>
                  )}
                />           
                <AdvancedSearchComponent onSearch={setSearchTerm} />                
                <div style={{display:"flex", justifyContent: "center"}} >
                <HelpPopup body={true}/>
                </div>
              </div>
            }
           {wasSearched ? setversearh(true) : setversearh(false)}
            {wasSearched &&
              <Layout
                header={
                  <Hidden only={['xs', 'sm']}>
                      <div className="header-base-layout">
                        <SearchBox
                          view = {InputView}
                          inputView={({ getInputProps, getButtonProps }) => (
                            <>
                              <div className="sui-search-box__wrapper">
                              <Typography variant="h6">
                                <input
                                  {...getInputProps({
                                    placeholder: "Ingresá el texto de búsqueda. Ej: tomo 251 pagina 787 / ley 26076 / deuda externa"
                                  })}
                                />
                              </Typography>
                              <Hidden only={['md', 'lg','xl']}>                              
                                <input
                                  {...getButtonProps({
                                    value: ""
                                  })}
                                />
                            </Hidden>
                              </div>
                              
                            <Hidden only={['xs','sm']}>
                                <input
                                  {...getButtonProps({
                                    value: "Buscar"
                                  })}
                                />
                            </Hidden>
                            </>
                          )}
                        
                        />
                      </div>
                  </Hidden>
                }
                sideContent={
                  <>
                   
                  
                   <Hidden only={['md', 'lg','xl']}>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                maxWidth='sm'
                                fullWidth={true}
                              >
                              <DialogTitle id="scroll-dialog-title">
                                <Grid container style={{alignItems: "center"}}>
                                  <Grid item xs={4} sm={4} >
                                    <Typography variant="h1">Filtrar por:</Typography>
                                  </Grid>
                                  <Grid item xs={8} sm={8} style={{display: "flex",justifyContent: "flex-end"}}>
                                   <Clearfilters></Clearfilters>
                                  </Grid>
                                </Grid>

                              </DialogTitle>
                             
                              <DialogContent dividers={scroll === 'paper'}>
                                <div className="side-div">                       
                                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                      >
                                      <Box  style={{display:"flex" , flexDirection: "column"}}> 
                                        <Typography variant="caption">Ordenar por : </Typography>                                     
                                        <Box>
                                          <Typography color="secondary" variant="caption"> {verssort}</Typography>
                                        </Box>
                                      </Box>
                                      </AccordionSummary>
                                          <AccordionDetails>
                                            {wasSearched && (
                                                  <Sorting
                                                    versort={changeSort}
                                                    sortOptions={[
                                                      {
                                                        name: "Más relevantes",
                                                        value: "",
                                                        direction: ""
                                                      },
                                                      {
                                                        name: "Más recientes",
                                                        value: "fecha",
                                                        direction: "desc"
                                                      },
                                                      {
                                                        name: "Más antiguos",
                                                        value: "fecha",
                                                        direction: "asc"
                                                      }
                                                    ]}
                                                    view={SortingViewP}
                                                  />
                                               )}
                                                            
                                          </AccordionDetails>
                                  </Accordion>
                                  <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel5bh-content"
                                      id="panel5bh-header"
                                    >
                                      <Typography variant="caption">Dictámenes</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Facet                                      
                                      field="indices"
                                      label="Dictámenes"
                                      filterType="all"
                                      view={MultiCheckboxFacetP}
                                    />
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel2bh-content"
                                      id="panel2bh-header"
                                    >
                                      <Typography variant="caption">Fecha</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>                             
                                      {!tomoSearch && <Facet mapContextToProps={context => {
                                                if (!context.facets.anio) return context;
                                                return {
                                                  ...context,
                                                  facets: {
                                                    ...(context.facets || {}),
                                                    anio: context.facets.anio.map(s => ({
                                                      ...s,
                                                      data: s.data.sort((a, b) => {
                                                        if (a.value > b.value) return 1;
                                                        if (a.value < b.value) return -1;
                                                        return 0;
                                                      })
                                                    }))
                                                  }
                                                };
                                              }}
                                      field="anio" label="Ingrese Rango" show="3000" view={props =>
                                        SliderRangeSelectFacetP({
                                          ...props,
                                          anioFilter:anioFilter,mini:anioFilterInicial 
                                        })} />}                                  
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel3bh-content"
                                      id="panel3bh-header"
                                    >
                                      <Typography variant="caption">Organismos</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box>
                                        <Facet                                        
                                        field="organismo"
                                        label="Organismo"
                                        filterType="any"
                                        view={MultiCheckboxFacetP}
                                      />
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel4bh-content"
                                      id="panel4bh-header"
                                    >
                                      <Typography variant="caption">Voces</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Facet                                      
                                      field="voces"
                                      label="Voces"
                                      filterType="all"
                                      view={MultiCheckboxFacetP}
                                    />
                                    </AccordionDetails>
                                  </Accordion>

                                </div>  
                              </DialogContent>
                                <DialogActions>
                                 
                                </DialogActions>
                            </Dialog>
                  </Hidden>
                  
                  <Hidden only={['xs','sm']}>
                    <div className="side-div" style={wasSearched ? sidebarShadow : {}}>
                      {wasSearched && (
                        <Sorting
                          label={"Ordenar por:"}
                          sortOptions={[
                            {
                              name: "Relevancia",
                              value: "",
                              direction: ""
                            },
                            {
                              name: "Más antiguos",
                              value: "fecha",
                              direction: "asc"
                            },
                            {
                              name: "Más recientes",
                              value: "fecha",
                              direction: "desc"
                            }
                          ]}
                          view={SortingView}
                        />
                      )}
                      <Grid container style={{alignItems: "center", marginTop:"40px",marginBottom:"20px" }}>
                          <Grid item md={8} lg={6} xl={6}>                         
                          <Typography variant="h6" style={{display: "flex", alignItems: "center", textTransform: "none"}}>
                            <Icon icon={baselineDateRange} style={{color: "#C2CFE0", fontSize: "25px", marginRight: "5px"}}/>
                            Filtrar por:
                            </Typography>
                          </Grid>
                          <Grid item md={6} lg={6} xl={6}>
                          <ClearfiltersWeb></ClearfiltersWeb>
                          </Grid>
                      </Grid>
                      <Facet
                        field="indices"
                        label="Dictámenes"
                        filterType="all"
                        view={MultiCheckboxFacet}/>
                       {!tomoSearch && <Facet mapContextToProps={context => {
                                  if (!context.facets.anio) return context;
                                  return {
                                    ...context,
                                    facets: {
                                      ...(context.facets || {}),
                                      anio: context.facets.anio.map(s => ({
                                        ...s,
                                        data: s.data.sort((a, b) => {

                                          if (a.value > b.value) return 1;
                                          if (a.value < b.value) return -1;
                                          return 0;
                                        })
                                      }))
                                    }
                                  };
                                }}
                        field="anio" label="Seleccionar Rango" show="3000" view={props =>
                          SliderRangeSelectFacet({
                            ...props,
                            anioFilter: anioFilter,mini:anioFilterInicial 
                          })} />}
                      <Facet
                        field="organismo"
                        label="Organismo"
                        filterType="any"
                        isFilterable={true}
                        view={MultiCheckboxFacet}
                        searchPlaceholder="Filtrar por Organismo"
                      />
                      <Facet
                        field="voces"
                        label="Voces"
                        filterType="all"
                        isFilterable={true}
                        view={MultiCheckboxFacet}
                        searchPlaceholder="Filtrar por Voces"
                      />

                    </div>
                  </Hidden>
                  </>
                }
                bodyContent={
                  <>
                  <Hidden only={['md', 'lg','xl']}>                    
                    <Container>                   
                        <SearchBox
                          view={InputView}
                          inputView={({ getInputProps, getButtonProps }) => (
                            <>
                              <div className="sui-search-box__wrapper">
                                <Typography variant="h6">
                                  <input 
                                    {...getInputProps({
                                      placeholder: "Ingresá el texto de búsqueda. Ej: tomo 251 pagina 787 / ley 26076 / deuda externa"
                                    })} ref={inputBus}/>
                                </Typography>
                                <Hidden only={['md', 'lg','xl']}>
                                  <input
                                    {...getButtonProps({
                                      value: ""
                                    })} />
                                </Hidden>
                              </div>

                              <Hidden only={['xs', 'sm']}>
                                <input
                                  {...getButtonProps({
                                    value: "Buscar"
                                  })} />
                              </Hidden>
                            </>
                          )} />
                    </Container>
                    <br></br>
                    <hr style={{marginInlineStart: '-2em', width:'120%', marginBlockEnd:'1em'}}/>
                  </Hidden>
                  <div>
                    {isLoading && <div className="loader"> <LinearProgress color="secondary" /> </div>}
                    {!isLoading && (
                      <Results
                        titleField="voces"
                        resultView={ResultView}/>
                    )}
                    <Hidden only={['md', 'lg','xl']}>
                     <ScrollTop volverini={volverinicio}>
                      <Fab color="secondary" size="small" aria-label="scroll back to top">
                        <ArrowUpwardRoundedIcon />
                      </Fab>
                  </ScrollTop>
                  </Hidden>
                  </div>
                </>
                }
                bodyHeader={          
                  <React.Fragment>
                    <Hidden only={['xs','sm']}>
                        {wasSearched && <PagingInfo 
                                          view={props =>
                                            PagingInfoView({
                                              ...props,
                                              showQuotesSearchText: emptyQuotesSearch
                                            })}/>}
                        {wasSearched && <ResultsPerPage view={ResultsPerPageView} options={[10, 25, 50]}/>}
                    </Hidden>
                    <Hidden only={['md', 'lg','xl']}>                     
                        {wasSearched && <PagingInfo 
                                          view={props =>
                                            PagingInfoViewP({
                                              ...props,
                                              showQuotesSearchText: emptyQuotesSearch
                                            })} />}
                        {wasSearched && <ResultsPerPage view={ResultsPerPageView} options={[10, 25, 50]}/>}
                            <Button onClick={handleClickOpen('paper')}>
                              <Typography variant="caption"><strong>Filtrar</strong>
                              </Typography>
                              <ExpandMoreIcon fontSize="small"/>                            
                            </Button>                      
                      </Hidden>
                  </React.Fragment>
                }
                
                bodyFooter={
                  <React.Fragment>
                    <Hidden only={['xs','sm']}>
                        <div>{!isLoading && ( <Paging view={PagingView} />)}</div>
                    </Hidden>
                  <Hidden only={['md', 'lg','xl']}>
                        <div>{!isLoading && (<LoadMore />)}</div>
                  </Hidden>
                  </React.Fragment>}
              />}              
              </div>
            </ErrorBoundary>
          </div>
        )}
      </WithSearch>
    </SearchProvider>
  );
}

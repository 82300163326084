import React from 'react';
import { makeStyles,Drawer, Divider,Box, Grid} from '@material-ui/core'; 
import { Items } from './Items';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';


const logobuscadicta = require('../assets/isologo_BDptn_Asset 2.svg');

const estilos = makeStyles(theme =>(
    {
        drawer: {
            width: 375,
            flexShrink: 0,
            '@media  (max-width: 400px)': {
                width: 350,
            },
          },
          drawerPaper: {
            width: "100%",
            backgroundColor:"#3B4651",
          },
          toolbar:theme.mixins.toolbar,
          toolbar: {minHeight: "70px",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px"
          },
        colorico: {
            color:"#ffffff",
            marginRight: "23px", 
        },
        colordivide: {
            backgroundColor:"#097CC1",
            height: "5px"
        },
        imglogo:{
            height: 50,
            width: 230,            
           
            '@media  (max-width:800px)': {
              height: 50,
              width: 230,           
            },
            '@media  (min-width: 801px) and (max-width: 1200px)': {
              height: 50,
              width: 230, 
            },
        },
    }))

const Menu = (props) => {
    const classes = estilos()
  return (
        <Drawer 
            className={classes.drawer}       
            classes={{
                paper:classes.drawerPaper
            }}
            anchor="left"
            variant={props.variant}
            open={props.open}
            onClose={props.onclose ? props.onclose : null}
        >
        <div className={classes.toolbar}>
            <Grid container>
                <Grid item xs={8} sm={8}>              
                    <Box 
                        className={classes.imglogo}
                        component="img"            
                        alt="Buscador de Dictámenes"
                        src={logobuscadicta.default}>           
                            
                    </Box>
                </Grid>
                <Grid item xs={4} sm={4} style={{textAlign:'right'}}>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        edge="end"
                        onClick={()=>props.accionabrir()}>
                    <FontAwesomeIcon className={classes.colorico}  icon={faXmark} >

                    </FontAwesomeIcon>
                    </IconButton>
                </Grid>
            </Grid>
        </div>
            <Divider className={classes.colordivide}/>
            <Items></Items>
        </Drawer>
  )
}
export default Menu
import React ,{useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Button,Box,Hidden, Grid } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import Menu from './Menu';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';

const logo = require('../assets/iso_ptn.png');
const logobuscadicta = require('../assets/isologo_BDptn_Asset 2.svg');


function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  // const trigger = useScrollTrigger(disableHysteresis: true,{ target: window ? window() : undefined },100);
  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 150,
    target: props.window ? window() : undefined
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

 HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}


const temaModi = makeStyles(theme =>(
    {
        offset:theme.mixins.toolbar, 
        menuButton: {
            color:"#ffffff",
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },},
        img:{
          height: 71,
          width: 213,
        },
        tooBarP: {
          justifyContent: "space-around",
          alignItems: "flex-end",
        },
        appBarP: { 
          height: "75px",
          padding: '5px',
          
        },   
        appBarL: {   
          height: "100px",
          padding: '10px',
           
        },
        colorico: {
          color:"#ffffff"
      },
      imglogo:{
        height: 50,
        width: 230,            
       
        '@media  (max-width:800px)': {
          height: 50,
          width: 230,           
        },
        '@media  (min-width: 801px) and (max-width: 1200px)': {
          height: 50,
          width: 230, 
        },
    },
      
    title: {
      flexGrow:1,
      textAlign: "center"
  },
    }))

     
export const Header = (props) => {
    const clases = temaModi();
    const dibujar=props.dondeesta;

    const[abrir,SetAbrir]=useState(false)
    const accionabrir = () => {
      SetAbrir(!abrir)
    }


  return (  
    <>
      <Hidden mdUp>
            <Menu  accionabrir ={accionabrir}
              variant = 'temporary'
              open = {abrir}
              onclose = {accionabrir}
              dondeesta={false}
              />
      </Hidden>
      <Hidden only={['xs','sm']}>
      <AppBar className={clases.appBarL} position="static" color="primary">
            <Toolbar>               
                <a title="Procuración del Tesoro" href="https://www.argentina.gob.ar/procuraciondeltesoro">
                <Box
                    component="img"
                    className={clases.img}               
                    alt="Procuración del Tesoro"
                    src={logo}>                  
                </Box>
                </a>
                <Typography variant="overline" className={clases.title}>
                </Typography>
                <Button href="./" variant="text" color="inherit"> 
                <Typography variant="overline" className={clases.title}>Búsqueda de Dictámenes 
                </Typography>
                </Button>          
            </Toolbar>
      </AppBar>
      </Hidden>
      <Hidden only={['md', 'lg','xl']}>
      <HideOnScroll {...props}>
        <AppBar className={clases.appBarP}  position='fixed' color="primary">
          <Toolbar className={clases.tooBarP} >
            <Grid container>
              <Grid item xs={10} sm={10} style={{textAlign:'left'}}>
                  <Box   
                      className={clases.imglogo}                    
                      component="img"            
                      alt="Buscador de Dictámenes"
                      src={logobuscadicta.default}>
                  </Box>
                </Grid>
                <Grid item xs={2} sm={2} style={{textAlign:'right'}}>
                {/* Menú Hamburguesa */}
                  <IconButton  
                      className={clases.menuButton} 
                      aria-label="menu"
                      onClick={()=>accionabrir()}
                      >
                    <FontAwesomeIcon className={clases.colorico}  icon={faBars} ></FontAwesomeIcon>
                  </IconButton> 
                </Grid>
              </Grid>
          </Toolbar>              
        </AppBar>
      </HideOnScroll>
      </Hidden>
    </>
  )
}

import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    marginLeft: 5
  }
}

const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress
    color="primary"
    className={props.classes.spinner}
    size={20}
  />
))

export default function LoadingButton(props) {
  const {
    children,
    loading,
    ...rest
  } = props

  return (
    <Button {...rest}>
      {!loading && children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  )
}
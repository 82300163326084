import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Layout from './views/Layout'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <GoogleReCaptchaProvider reCaptchaKey={window._env_.REACT_APP_RECAPTCHA_PUBLIC}>
        <Layout>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Layout>
    </GoogleReCaptchaProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from "react";
import { Route, Routes, } from "react-router-dom";

import Buscador from './Buscador.js';
import Admin from './Admin.js';
import { RequireToken } from './components/auth.js';
import Login from './components/login.js';
import { unsetToken } from "./components/auth.js";
import AvisoHistoricosPopup from "./views/avisoHistoricosPopup.js";

export default function App() {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => setOpen(true), 1000);
  }, [])

  return (
    <><Routes>
      <Route path="/" element={<Buscador />} />
      <Route
        path="/admin"
        element={<RequireToken>
          <Admin />
        </RequireToken>} />
      <Route
        path="/login"
        element={<Login />} />
    </Routes></>
  );
}

import React from "react";
import Popup from "reactjs-popup";
import { Paper, TextField, Divider, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useCallback, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingButton from "./loadingButton";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

import filetypeinfo from "magic-bytes.js";

import doctrinavacia from '../assets/doctrina.txt';

class EsLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "yyyy-MM-dd", { locale: this.locale });
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    display: 'flex', 
    flexFlow: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    fontSize: '15px', 
    fontWeight: '500', 
    fontStyle: 'normal', 
    fontFamily: "'Poppins', sans-serif", 
    color: '#192a3e' 
  },
  closeButton: {
    fontSize: '20px', 
    fontFamily: "sans-serif", 
    color: '#707683', 
    cursor: 'pointer' 
  },
  modal: {
    display: 'flex', 
    flexFlow:'column', 
    height: '100%'
  },
  column: {
    display:'flex', 
    flexFlow:'column', 
    justifyContent: 'space-between',
    height: 'inherit',
    color: '#707683',
  },
  paper: {
    maxHeight: '600px',
    // overflow: 'auto',
    border: 'solid 0px', 
    padding: '25px'
  },
  paperContent: {
    maxHeight: '450px',
    overflow: 'auto',
    border: 'solid 0px', 
    padding: '25px'
  },
  divider: {
    margin: '5px -25px'
  },
  input: {
    display: 'none',
  }
}));


export default function AddDictamenPopup(props) {
  const classes = useStyles();
  const [titulo, setTitulo] = React.useState(props.titulo);
  const [tomo, setTomo] = React.useState(props.tomo);
  const [pagina, setPagina] = React.useState(props.pagina);
  const [numero, setNumero] = React.useState(props.numero);
  const [expediente, setExpediente] = React.useState(props.expediente);
  const [organismo, setOrganismo] = React.useState(props.organismo);
  const [fecha, setFecha] = React.useState(props.fecha);
  const [dictamenData, setDictamenData] = React.useState("");
  const [doctrinaData, setDoctrinaData] = React.useState("");
  const [tituloError, setTituloError] = React.useState(props.tituloRequired);
  const [tomoError, setTomoError] = React.useState(props.tomoRequired);
  const [paginaError, setPaginaError] = React.useState(props.paginaRequired);
  const [numeroError, setNumeroError] = React.useState(props.numeroRequired);
  const [expedienteError, setExpedienteError] = React.useState(props.expedienteRequired);
  const [organismoError, setOrganismoError] = React.useState(props.organismoRequired);
  const [dictamenError, setDictamenError] = React.useState(props.dictamenRequired);
  const [doctrinaError, setDoctrinaError] = React.useState(props.doctrinaRequired);
  const [isLoading, setLoading] = React.useState(false);
  const [dictamenName, setDictamenName] = React.useState("");
  const [doctrinaName, setDoctrinaName] = React.useState("");

  const [loadingDictamen, setLoadingDictamen] = React.useState(false);
  const [loadingDoctrina, setLoadingDoctrina] = React.useState(false);

  useEffect(() => {
    if (!props.open) {
      setDictamenName("");
      setDoctrinaName("");
    }
  }, [props.open]);

  const SubmitButton = withStyles((theme) => ({
    root: {
      color: '#FFF',
      backgroundColor: '#097CC1',
      borderRadius: '4px',
      fontSize: '12px',
      padding: '10px 55px',
      marginTop: '20px',
      '&:hover': {
        backgroundColor: '#097CC1',
        opacity: '0.9',
      },
    },
  }))(LoadingButton);

  const onClick = async (e) => {
    setLoading(true);
    let doctrina = doctrinaData.replace(/^data:application\/pdf;base64,/, '');
    let dictamen = dictamenData.replace(/^data:application\/pdf;base64,/, '');
    e.preventDefault();
    let formData = {
      "tomo": tomo,
      "pagina": pagina,
      "numero": numero,
      "fecha": fecha,
      "expediente_numero": expediente,
      "organismo": organismo,
      "titulo": titulo
    }
    
    if (doctrina) {
      formData["doctrina"] = doctrina;
    }else{
      const response = await fetch(doctrinavacia); // la ruta del archivo vacío
      const text = await response.text(); // Convierte la respuesta a texto
      formData["doctrina"] = text;
       }

    if (dictamen) {
      formData["pdf"] = dictamen
    }
   
    if (props.id) {
      props.onSubmit(props.id, formData).then(() => {
        setLoading(false);
        props.onSearch("");
        props.onClose();
      });
    } else {
      props.onSubmit(formData).then(() => {
      
        setLoading(false);
        props.onSearch("");
        props.onClose();
      }).catch((e) => {        
        if (e.response.status == 303) {
          setLoading(false);
          setNumeroError(true);
        }
      });
    }
  }

  const formatDate = (e) => {
    setFecha(format(e, "yyyy-MM-dd"));
  }

  const dictamenFileChange = useCallback( async (event) => {
    setLoadingDictamen(true);
    const filedata = event.target.files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      setDictamenData(reader.result);
      setDictamenError(false);
      setDictamenName(filedata.name);
      setLoadingDictamen(false);
    };
    await reader.readAsDataURL(filedata);
}, []);

  const doctrinaFileChange = useCallback( async (event) => {
    setLoadingDoctrina(true);
    const filedata = event.target.files[0];
    const reader = new FileReader();
   
    reader.onload = () => {
      setDoctrinaData(reader.result);
      setDoctrinaError(false);
      setDoctrinaName(filedata.name);
      setLoadingDoctrina(false);
    };
    await reader.readAsDataURL(filedata);
  }, []);

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('yyyy-mm-dd');

  return (
    <Popup open={props.open} trigger={props.trigger} /*modal*/ contentStyle={{maxWidth: '800px', padding: '0', background: 'none', border: '0'}} onClose={props.onClose} closeOnDocumentClick={false} closeOnEscape={false}>
    {close => (
      <Paper elevation={4} className={classes.paper}>
      {/* {isLoading && <div className="loader"><CircularProgress/></div>}
      {!isLoading && ( */}
        <form onSubmit={(e) => {onClick(e)}}>
          <div className={classes.modal}>
            <div className={classes.heading} >
              {props.header}
              <a href onClick={close} className={classes.closeButton}> X </a>
            </div>
            <Divider className={classes.divider}/>
            <Paper elevation={0} className={classes.paperContent}>
              <div className={classes.column} >
                <TextField required={props.tituloRequired}
                  error={tituloError}
                  onChange={event => {setTituloError(event.target.value === ""); setTitulo(event.target.value);}}
                  style={{display:"flex",padding:"10px"}}
                  id="as-titulo"
                  label="Título"
                  multiline
                  rows={4}
                  variant="outlined"
                  defaultValue={props.titulo}/>
                <TextField required={props.tomoRequired}
                  error={tomoError}
                  onChange={event => {setTomoError(event.target.value === ""); setTomo(event.target.value);}}
                  style={{display:"flex",padding:"10px"}}
                  id="as-tomo"
                  label="Tomo"
                  variant="outlined"
                  defaultValue={props.tomo}/>
                <TextField required={props.paginaRequired}
                  error={paginaError}
                  onChange={event => {setPaginaError(event.target.value === ""); setPagina(event.target.value);}}
                  style={{display:"flex",padding:"10px"}}
                  id="as-pagina"
                  label="Página"
                  variant="outlined"
                  defaultValue={props.pagina}/>
                <TextField required={props.numeroRequired}
                  error={numeroError}
                  onChange={event => {setNumeroError(event.target.value === ""); setNumero(event.target.value);}}
                  style={{display:"flex",padding:"10px"}}
                  id="as-numero"
                  label="Número"
                  variant="outlined"
                  defaultValue={props.numero}/>
                <TextField required={props.expedienteRequired}
                  error={expedienteError}
                  onChange={event => {setExpedienteError(event.target.value === ""); setExpediente(event.target.value);}}
                  style={{display:"flex",padding:"10px"}}
                  id="as-expediente"
                  label="Número de Expediente"
                  variant="outlined"
                  defaultValue={props.expediente}/>
                <TextField required={props.organismoRequired}
                  error={organismoError}
                  onChange={event => {setOrganismoError(event.target.value === ""); setOrganismo(event.target.value);}}
                  style={{display:"flex",padding:"10px"}}
                  id="as-organismo"
                  label="Organismo"
                  variant="outlined"
                  defaultValue={props.organismo}/>
                <Typography>Fecha del dictamen:</Typography>
                <DatePicker
                  selected={parse(fecha, "yyyy-MM-dd", new Date())}
                  onChange={formatDate}
                  dateFormat="yyyy-MM-dd"
                  customInput={<MaskedInput
                                pipe={autoCorrectedDatePipe}
                                mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                keepCharPositions={true}
                                guide={true}/>}
                  fullWidth/>
                <input required={props.dictamenRequired}
                  error={dictamenError}
                  accept="application/pdf"
                  className={classes.input}
                  id="as-dictamen"
                  type="file"
                  onChange={e => dictamenFileChange(e)}/>
                <label htmlFor="as-dictamen" style={{display:"flex",padding:"10px"}}>
                  <LoadingButton variant="contained" component="span" disableElevation fullWidth loading={loadingDictamen}>
                    Dictamen...
                  </LoadingButton>
                </label>
                <Typography>{dictamenName}</Typography>
                <input required={props.doctrinaRequired}
                  error={doctrinaError}
                  accept=".pdf"
                  className={classes.input}
                  id="as-doctrina"
                  type="file"
                  onChange={e => doctrinaFileChange(e)}/>
                <label htmlFor="as-doctrina"  style={{display:"flex",padding:"10px"}}>
                  <LoadingButton variant="contained" component="span" disableElevation fullWidth loading={loadingDoctrina}>
                    Doctrina...
                  </LoadingButton>
                </label>
                <Typography>{doctrinaName}</Typography>
              </div>
            </Paper>
            <SubmitButton type='submit'
              size="medium"
              color="primary"
              disabled={(props.tituloRequired && tituloError) || (props.tomoRequired && tomoError) || (props.paginaRequired && paginaError) || (props.numeroRequired && numeroError) || (props.expedienteRequired && expedienteError) || (props.organismoRequired && organismoError) || (props.dictamenRequired && dictamenError) || (props.doctrinaRequired && doctrinaError) || isLoading}
              loading={isLoading}>
              {props.buttonName}
            </SubmitButton>
            </div>
          </form>
      {/* )} */}
      
      </Paper>
    )}
    </Popup>
  );
}

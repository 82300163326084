import React , {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function Rangofechah(props) {
var [values, setValue] = React.useState(props.value);
var [startDate, setStartDate] = useState(new Date(props.value[1]+"-01-02"));

// Esto es para que la primera vez que carga se haga efectivo el filtro de los últimos anios
  /*React.useEffect(() => {
    props.onChange(values[0])
    if (values[1]) { props.addFilter(values[1]) }
  }, [])
*/
// Cada vez que cambia el valor de value se actualiza en el estado
  React.useEffect(() => {
      setValue(props.value);      
  }, [props.value])

  const handleChange = (date) => {
    if (date==null ) {return}
      if (date.getFullYear()<9999)
      {
        setStartDate(date);
        setValue(values[1]=date.getFullYear());
        setValue(values);
        props.onChange(values[0])
        if (values[1]) { props.addFilter(values[1]) }
      }
      else{
        return
      }
  };
  const maximum = props.marks[props.marks.length - 1].value

return (
    <DatePicker selected={startDate} onChange={handleChange} dateFormat="yyyy" showYearPicker/>
    ) 
}

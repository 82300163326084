import PropTypes from "prop-types";
import React from "react";
import { Typography } from '@material-ui/core';

function quotesSearchMessage(searchTerm) {
  return (
    <div>
      No se han encontraron resultados para {searchTerm}.
    </div>
  )
}

function PagingInfoViewP({
  className,
  end,
  searchTerm,
  start,
  totalResults,
  showQuotesSearchText,
  ...rest
}) {
  const cleanSearchTerm = searchTerm.replace(/['"]+/g, '');
  const advancedSearch = searchTerm.includes("BusquedaAvanzada")
  if (advancedSearch) {
    var params = searchTerm.match(/(?:BusquedaAvanzada: tomo )(.*); (?:pagina) (.*); (?:dictamen) (.*); (?:fecha) (.*); (?:tema) (.*)/i)
    var tomo = params[1]
    var pagina = params[2]
    var expediente = params[3]
    var fecha = params[4]
    var tema = params[5]
  }

  return (
    <div style = {{maxWidth:"80%"}}>
    <Typography variant="caption">
    {showQuotesSearchText && quotesSearchMessage(searchTerm)}
    <div {...rest}>
       <strong>{totalResults} Resultados</strong>
    </div>
    </Typography>
   
    </div>
    
  );
}

PagingInfoViewP.propTypes = {
  end: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  className: PropTypes.string,
  showQuotesSearchText: PropTypes.bool
};

export default PagingInfoViewP;

import runRequest from "./runRequest";
import buildRequest from "./buildRequest";

function combineAggregationsFromResponses(responses) {
  return responses.reduce((acc, response) => {
    return {
      ...acc,
      ...response.aggregations
    };
  }, {});
}

function removeAllFacetsExcept(body, facetName) {
  return {
    ...body,
    aggs: {
      [facetName]: body.aggs[facetName]
    }
  };
}

function changeSizeToZero(body) {
  return {
    ...body,
    size: 0
  };
}

async function getIndexFacetCounts(state, indexFacetNames) {
  const responses = await Promise.all(
    // Note that this could be optimized by *not* executing a request
    // if not filter is currently applied for that field. Kept simple here for clarity.
    indexFacetNames.map(facetName => {
      let {searchTerm, body} = buildRequest(state);
      body = changeSizeToZero(body);
      body = removeAllFacetsExcept(body, facetName);
      return runRequest({searchTerm: searchTerm, body: body}, true, false);
    })
  );
  return combineAggregationsFromResponses(responses);
}

/**
 * This function will re-calculate facets that need to be considered
 * "disjunctive" (also known as "sticky"). Calculating sticky facets correctly
 * requires a second query for each sticky facet.
 *
 * @param {*} json
 * @param {*} state
 * @param {string[]} indexFacetNames
 *
 * @return {Promise<Object>} A map of updated aggregation counts for the specified facet names
 */
export default async function applyIndexFaceting(
  json,
  state,
  indexFacetNames
) {
  const indexFacetCounts = await getIndexFacetCounts(
    state,
    indexFacetNames
  );

  return {
    ...json,
    aggregations: {
      ...json.aggregations,
      ...indexFacetCounts
    }
  };
}

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { Typography } from '@material-ui/core';



const PtnSlider = withStyles({
      root: {
        color: '#097CC1',
        height: 8,
        marginTop: 4
      },
      thumb: {
        height: 15,
        width: 15,
        backgroundColor: '#097CC1',
        marginTop: -6,
        //marginLeft: -12,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-110% + 9px)',
      },
      track: {
        height: 3,
        borderRadius: 4,
      },
      rail: {
        height: 2,
        borderRadius: 4,
        opacity: 1
      },
       mark: {
        backgroundColor: '#bfbfbf',
        height: 6,
        width: 1,
        marginTop: -2,
      },
      markActive: {
        backgroundColor: "#097CC1",
      },
      })(Slider);

function renderText(values) {
  return (values.length > 1) ?
            (<span> Entre el año {values[0]} y {values[1]}</span>) :
            (<span> Durante el año {values[0]} </span>)
}

const CustomSlider = ({ value, onChangeCommitted, marks, max, min, onChange, label , mini}) => {
  return (<div className="sui-range-facet">
            <PtnSlider
              value={value}
              valueLabelDisplay="auto"
              getAriaLabel={() => 'selector'}
              aria-labelledby={`${label}-label`}
              onChangeCommitted = {onChangeCommitted}
              onChange= {onChange}
              marks={marks}
              max={mini[1]}
              min={mini[0]}
              step={1}
            />
            <Typography variant="h3">
              {renderText(value)}
            </Typography>
          </div>
  )}

  export default CustomSlider;

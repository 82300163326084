
import { createTheme } from "@material-ui/core";
import {esES} from '@material-ui/core/locale';

const ayuda_default = require('../assets/ayuda_flota_default.png');
const ayuda_hover = require('../assets/ayuda_flota_hover.png');

const ayuda_defaultxs = require('../assets/ayuda_flota_defaultxs.png');
const ayuda_hoverxs = require('../assets/ayuda_flota_hoverxs.png');


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 601,
      md: 801,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      '"Bebas Neue"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  }, 
    palette: {
        primary: {
          main: "#707683",
        },
        secondary: {
          main: "#097CC1",
        },
        default: {
          main: "#323c47",
        }
      },
      overrides: {
        MuiDialog: {
          scrollPaper: {
            alignItems: "flex-start",
            marginTop: "7em",
            marginLeft: "2em",
          },
          paperScrollPaper: {
              height:"60%",
              maxHeight: "calc(100% - 64px)",
          },         
        },
        MuiDialogContent: {       
            dividers: {
              padding: "0px 0px 0px 0px"
            },
        },
        
        MuiAccordion: {
          root: {
            height: "100%",
          },
        },
        MuiContainer: {
          root: {
            '@media  (max-width: 800px)': {             
              paddingLeft: "0px",
              paddingRight: "0px",
            },
        },
      },
        MuiInputBase: {
          root: {
            fontFamily: "'Roboto', sans-serif",
            fontSize: "13pt",
               '@media (max-width: 800px)': {
             
                fontSize: "13pt",
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize:  "13pt",
              },
          },
        },
        MuiFormGroup: {
          root: {
            alignItems: "flex-end",
          },
        },
        MuiFormControlLabel: {
          labelPlacementStart: {
            marginLeft: "10px",
          },
        },
        MuiFab: {
          root:{
            position: 'fixed',
            bottom: 20,
            right: 60,
            zIndex:2,
            backgroundColor:"#FFF",
            background: `url(${ayuda_default}) no-repeat center top`,
            '@media (max-width: 800px)': {
                background: "none",
            },
            // border: "4px solid #097CC1",
            width: 80,
            height: 80,
            textTransform: "none",
            boxShadow: "none",
            animation: "glow 1s infinite alternate",
            "&:hover": {
              background: `url(${ayuda_hover}) no-repeat center top`,
                 '@media (max-width: 800px)': {
                    background: "none",
                },
            }
          }
        },
        MuiSvgIcon: {
          colorPrimary: {
            color: "#C2CFE0",
          },
            colorSecondary: {
              color: "#FFFF",
            },
          },
        MuiList:{
          root: {
            height:"84px",
          },
          padding:{
            paddingTop: "0px",
          }
        }, 
        MuiAppBar: {
          colorPrimary: {
            color: "#3B4651",
            backgroundColor: "#3B4651",
          },

          root: {
            justifyContent: "center",
          },

          before: {
            content: "",
            background: "#323c47",
            position: "absolute",
            height: "100%",
            width: "200vw",
            left: "-100vw",
            zIndex: "-1",
          },
        },
        MuiListItemText:{
          root:{
            marginTop:"4px"
          }
        },
        MuiButton: {
          outlinedPrimary: {
            color: "#097CC1",
            border: "1px solid rgb(27, 160, 241)",
            textTransform: "",
            fontSize: "11pt",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              border: "1px solid rgb(27, 160, 241)",
              opacity: 0.7,
              boxShadow: "0px 0px 1px 0px"
            }
            // boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)"
          },
          sizeSmall: {
            width: 127,
             '@media  (max-width: 800px)': {
              width: 110,
            },
            '@media  (min-width: 801px) and (max-width: 1200px)': {
              width: 110,
            },
          },
          root: {
            textTransform: "",
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
          },
          outlinedSizeSmall: {
            fontSize: '0.92rem',
             '@media  (max-width: 800px)': {
              fontSize:  '0.92rem',
            },
            '@media  (min-width: 801px) and (max-width: 1200px)': {
              fontSize: '0.92rem',
            },
          },
        },
        MuiTypography: {
          // Titulo busqueda dictamenes
          overline: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "22pt",
               '@media  (max-width: 800px)': {
                fontSize: '22pt',
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: '22pt',
              },
            color: "#FFFFFF",
            textTransform: "none"
          },
          // Texto de Voces
          h1: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "13pt",
               '@media  (max-width: 800px)': {             
                fontSize:"10.5pt", // Pedido F: 14px y convertido a pt 
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize:"13pt",
              },
            color: "#323C47"
          },
          // Texto organismo
          subtitle1: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "12pt",
               '@media  (max-width: 800px)': {
                fontSize: "9.75pt",// Pedido F: 13px y convertido a pt 
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: "12pt",
              },
            color: "#323C47"
          },
          // Texto datos de doctrina
          subtitle2: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "12pt",
               '@media  (max-width: 800px)': {
                fontSize:"9pt",// Pedido F: 12px y convertido a pt 
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: "12pt",
              },
            color: "#707683",
            lineHeight: 1.1
          },
          // Texto doctrina
          body1: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "11pt",
               '@media  (max-width: 800px)': {
                  fontSize:"10.5pt", // Pedido F: 14px y convertido a pt 
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: "11pt",
              },
            color: "#323C47"
          },
          body2: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "10pt",
            color: "#097CC1"
          },
          caption: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "10pt",
               '@media  (max-width: 800px)': {
                fontSize: '9pt', 
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize:"10pt",
              },
            color: "#717171"
          },
          // Titulo filtros
          h6: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "12pt",
            color: "#707683",
            '@media  (max-width: 800px)': {
              fontSize: "12pt",
            },
          },
          // Texto ayuda home
          h5: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "11pt",
               '@media  (max-width: 800px)': {
                fontSize: "11pt",
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: "11pt",
              },
            color: "#097CC1"
          },
          // Opciones checkbox
          h4: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "11pt",
               '@media  (max-width: 800px)': {
                fontSize: "11pt",
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize:  "11pt",
              },
            color: "#707683"
          },
          // Texto slider
          h3: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 400,
            fontStyle: "normal",
            fontSize: "9pt",
               '@media  (max-width: 800px)': {
                fontSize:  "9pt",
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: "9pt",
              },
            color: "#707683"
          },
          // Texto ayuda
          h2: {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "11pt",
               '@media  (max-width: 800px)': {
                fontSize: "11pt",
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: "11pt",
              },
            color: "#0563c1"
          },
        }
      }
},esES );


export default theme


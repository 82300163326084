import PropTypes from "prop-types";
import React from "react";
import { Typography } from '@material-ui/core';
import { FacetValue } from "@elastic/react-search-ui-views/lib/types";
import { appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";
import RenderSlider from "../components/sliderComponent.js";
import { Icon } from '@iconify/react';
import baselineDateRange from '@iconify/icons-ic/baseline-date-range';
import _ from 'lodash';


const minimumYear = 1942;

function reconfigureValueAndMarks(anioFilter, marks) {
  var result = marks
  var newMarks = anioFilter.map(function(o) {
    return { value: parseInt(o), label: ""}
  })
  result = _.sortBy((_.uniqBy(result.concat(newMarks), 'value')),'value')
  return result
}

function SliderRangeSelectFacet({ className, label, onRemove, onSelect, options, onChange, anioFilter, mini }) {
  if (anioFilter.length>0 && mini.length>0)
  {
    if (_.isEqual(mini, anioFilter) )
    {
      anioFilter=[];
    }
  }
  var value = options.filter(o => o.selected).map(o => o.value);
  var marks = options.map(function(o) {
    return { value: parseInt(o.value), label: ""}
  })

  if (!_.isEqual(value, anioFilter) && (anioFilter.length > 0)) {
    marks = reconfigureValueAndMarks(anioFilter, marks)
    value = anioFilter
  }
  const maximum = marks[marks.length - 1].value
  const minimum = (maximum > minimumYear) ?
                    Math.max(marks[0].value, minimumYear) :
                    marks[0].value;
  const defaultValues = [minimum,maximum]

  if (value[0] < marks[0]) {value[0] = marks[0]}
  if (value[1] > marks[marks.length - 1]) {value[1] = marks[marks.length - 1]}

  if (!value.length > 0) {
    marks = reconfigureValueAndMarks(defaultValues, marks)
    value = defaultValues
  }
// Agregado sh 09/02/2024
  if ((value.length === 1) && value [0]!= defaultValues[0]) {
      value [1]= value [0];
  }

  if ((value.length === 1) && value [1]!= defaultValues[1]) {
    value [1]= value [0];
  }
// Agregado sh 15/02/2024 
  if (anioFilter.length===0 && defaultValues.length>0)
  {
    anioFilter=defaultValues;
    marks = reconfigureValueAndMarks(anioFilter, marks)
    value = anioFilter
  }
  return (

    <div className={appendClassName("sui-facet", className)}>
      <div>
        <div className="sui-facet__title">
        <Typography variant="h6" style={{display: "flex", alignItems: "center", textTransform: "none"}}>
        <Icon icon={baselineDateRange} style={{color: "#C2CFE0", fontSize: "25px", marginRight: "5px"}}/>
         <span id={`${label}-label`}>{label}</span>
        </Typography>
        </div>
          <RenderSlider mini={mini} value={value} marks={marks} addFilter={onSelect} onChange={onChange} label={label}/>
      </div>
    </div>
  );
}

SliderRangeSelectFacet.propTypes = {
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(FacetValue).isRequired,
  className: PropTypes.string
};

export default SliderRangeSelectFacet;

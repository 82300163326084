import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AddDictamenPopup from './addDictamenPopup';
import format from "date-fns/format";
import { RequireToken, unsetToken } from './auth';

const ColorButton = withStyles((theme) => ({
    root: {
        color: '#097CC1',
        backgroundColor: '#FFF',
        boxShadow: '0px 2px rgb(0 0 0 / 20%)',
        borderRadius: '0',
        fontSize: '18px',
        padding: '10px 55px',
        '&:hover': {
            color: '#52ace1',
            backgroundColor: '#FFF',
            boxShadow: '0px 2px rgb(0 0 0 / 20%)',
        },
        '&:active': {
            color: '#085482',
            backgroundColor: '#FFF',
        }
    },
}))(Button);

export default function AddDictamenComponent(props) {
    const [open, setOpen] = React.useState(false);
    const closeModal = () => setOpen(false);

    // const AdvButton = () => {
    //   return (
    //   )
    // }

    return (
        <div>
            <RequireToken>
                {/* <AdvButton ></AdvButton> */}
                <ColorButton variant="contained" color="primary" onClick={() => { setOpen(open => !open) }} disableElevation>
                    Cargar Nuevo Dictamen
                </ColorButton>
                <AddDictamenPopup open={open}
                    onClose={closeModal}
                    onSubmit={props.onSubmit}
                    onSearch={props.onSearch}
                    fecha={format(new Date(), "yyyy-MM-dd")}
                    buttonName="Cargar Nuevo Dictamen"
                    header="Nuevo Dictamen"
                    tituloRequired
                    tomoRequired
                    paginaRequired
                    numeroRequired
                    expedienteRequired
                    organismoRequired
                    fechaRequired
                    dictamenRequired
                     />
            </RequireToken>
        </div>
    );
}
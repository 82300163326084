import React,{useState} from 'react';
import {
List, 
ListItem,
ListItemText,
Divider,
makeStyles,
Box,
Grid
} from "@material-ui/core";

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const logo = require('../assets/iso_ptn.png');
const portomo = require('../assets/ayuda_por_tomo.svg');
const paraobtener = require('../assets/ayuda_para_obtener.svg');
const comofiltrar = require('../assets/ayuda_como_filtrar.svg');
const comoleer = require('../assets/ayuda_como_leer.svg');



const estilo1 = makeStyles((theme) => ({
    root: {
        "& span": {
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "16px",
              '@media  (max-width: 800px)': {
                fontSize: '16px',
              },
              '@media  (min-width: 801px) and (max-width: 1200px)': {
                fontSize: '16px',
              },
            color: "#ffffff",
        }
    },
    colordivide: {
        backgroundColor:"#ffffff"
    },
    nested: {
      paddingLeft: theme.spacing(6),
    },
    colorico: {
      color:"#ffffff"
  },
  img:{
    height: 71,
    width: 213,
    '@media  (max-width: 800px)': {
        height: 35.5,
        width: 106.5,
    },
    '@media  (min-width: 801px) and (max-width: 1200px)': {
        height: 71,
        width: 213,
    },
  },
 imgtomo:{
    '@media  (min-width: 450px)': {
      height: "614px",
      width: "350px",
    },   
  }, 
   imgobtener:{
    '@media  (min-width: 450px)': {
      height: "166px",
      width: "350px",
    },   
  },
  imgcomofiltrar:{
    '@media  (min-width: 450px)': {
      height: "817px",
      width: "350px",
    },   
  },
  imgcomoleer:{
    '@media  (min-width: 450px)': {
      height: "325px",
      width: "350px",
    },   
  },
    fondocollapse: {
      backgroundColor:"#323C47",
    },
    paper: {
    margin: theme.spacing(8),
    padding: theme.spacing(8),
    textAlign: 'center',
  },

  }));

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  
export const Items = () => {
    const classes = estilo1();
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    const handleClick = () => {
      setOpen(!open);
    };
    const handleClick1 = () => {
      setOpen1(!open1);
    };
  return (
    <div  className={classes.root}>
        <List component='nav'>
            <ListItem className={classes.fondocollapse} button > 
              <ListItemLink href="./">
                  <ListItemText primary='Inicio'/>
              </ListItemLink>
            </ListItem>
            <Divider className={classes.colordivide} />

           { /* <ListItem button>
              <ListItemLink href={`${window._env_.REACT_APP_NOVEDADES_URL}`} >
                  <ListItemText  primary='Novedades de Dictámenes'/>
              </ListItemLink>
  </ListItem> */}
        
            <Divider className={classes.colordivide}/>        
            <ListItem className={classes.fondocollapse} button  onClick={handleClick1}>
              <ListItemLink>
                  <ListItemText primary='Contacto'/>
                    {open1 ? <ExpandLess className={classes.colorico}/> : <ExpandMore className={classes.colorico}/>}
              </ListItemLink>
            </ListItem>            
                <Collapse className={classes.fondocollapse} in={open1} timeout="auto" unmountOnExit>                  
                    <ListItem button className={classes.nested}>              
                        <ListItemText primary="e.mail: biblioteca@ptn.gob.ar" />
                    </ListItem>
                    <ListItem className={classes.nested}>              
                        <ListItemText primary="Tel.: +54 11 4804-3482 / 5385 / 7598" />
                    </ListItem>
                    <ListItem style = {{paddingBottom:"0px"}} className={classes.nested}>              
                        <ListItemText style = {{marginBottom:"0px"}} primary="Posadas 1641 · CP: 1112" />
                    </ListItem>
                    <ListItem style = {{paddingTop:"0px"}} className={classes.nested}>       
                        <ListItemText style = {{marginTop:"0px"}} primary="Buenos Aires · Argentina" />
                    </ListItem>                 
                </Collapse>

        <Divider className={classes.colordivide}/>
            <ListItem className={classes.fondocollapse} button onClick={handleClick}>
              <ListItemLink>
                  <ListItemText primary='Ayuda'/>
                  {open ? <ExpandLess className={classes.colorico}/> : <ExpandMore className={classes.colorico}/>}
              </ListItemLink>
            </ListItem>
              <Collapse className={classes.fondocollapse} in={open} timeout="auto" unmountOnExit>
                  <ListItem button className={classes.nested}>              
                      <ListItemText primary="¿Con qué podemos ayudarte?" />
                  </ListItem>
                  <ListItem button className={classes.nested}>              
                      <ListItemText primary="Como buscar" />
                  </ListItem>
                  <ListItem className={classes.nested}>
                    <Box
                      className={classes.imgtomo}
                      component="img"            
                      alt="por tomo"
                      src={portomo.default}> 
                    </Box>
                  </ListItem>
                  <ListItem className={classes.nested}>
                    <Box
                      className={classes.imgobtener}   
                      component="img"            
                      alt="como obtener"
                      src={paraobtener.default}> 
                    </Box>
                  </ListItem>
                  <ListItem button className={classes.nested}>              
                      <ListItemText primary="Como filtrar tus resultados" />
                  </ListItem>
                  <ListItem className={classes.nested}>
                    <Box
                      className={classes.imgcomofiltrar}   
                      component="img"            
                      alt="como filtrar"
                      src={comofiltrar.default}> 
                    </Box>
                  </ListItem>
                  <ListItem button className={classes.nested}>              
                      <ListItemText primary="Como leer los resultados" />
                  </ListItem>
                  <ListItem className={classes.nested}>
                    <Box
                      className={classes.imgcomoleer}   
                      component="img"            
                      alt="como leer"
                      src={comoleer.default}> 
                    </Box>
                  </ListItem>                  
              </Collapse>
        
        <Divider className={classes.colordivide}/>
        <Grid className={classes.paper} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
              className={classes.img}   
              component="img"            
              alt="Buscador de Dictámenes"
              src={logo}> 
          </Box>
        </Grid>
        </List>
    </div>
  )
}


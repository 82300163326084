import React from 'react';
import { withStyles,makeStyles} from '@material-ui/core/styles';
import { Button, Grid ,Hidden} from '@material-ui/core';
import AdvancedSearchPopup from './advancedSearchPopup';
import Headerp from '../views/Headerp';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#097CC1',
    backgroundColor: '#FFF',    
    borderRadius: '0',
    fontSize: '18px',
      '@media  (max-width: 600px)': {
        fontSize: '14px',
        marginTop:'10px',
      },
      '@media  (min-width: 601px) and (max-width: 768px)': {
        fontSize: '18px',
        marginTop:'10px',
      },
      '@media  (min-width: 769px) and (max-width: 1200px)': {
        fontSize: '18px',
        marginTop:'10px',
      },
    padding: '10px 0px ',
    '&:hover': {
      color: '#52ace1',
      backgroundColor: '#FFF',
    },
    '&:active': {
      color: '#085482',
      backgroundColor: '#FFF',
    }
  },
}))(Button);

const temaModi = makeStyles(theme =>(
  {
    conteStyle: {
    padding:"50px", 
    },
    itemStyle: {
      boxShadow: '0px 2px rgb(0 0 0 / 20%)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }    
    },
    itemStyleb: {
      boxShadow: '0px 2px rgb(0 0 0 / 20%)',
    },
    conteStyleEscudo: {
      padding:"50px", 
      marginTop:"250px",
      textAlign:"center",
      },
  }))
 
export default function AdvancedSearchComponent(props) {
  const clases = temaModi();
  const AdvButton = () => {
    return (
    <ColorButton variant="contained" color="primary" disableElevation>
      Búsqueda Avanzada
    </ColorButton>
    )
  }

  return (
    <div>
      <Grid container className={clases.conteStyle} justifyContent="center">
         { <Grid item className={clases.itemStyle} xs={10} sm={10} md={4} lg={4} xl={4} container justifyContent="center">
            <ColorButton onClick={(e) => { e.preventDefault(); window.location.href=`${window._env_.REACT_APP_NOVEDADES_URL}`;}} variant="contained" color="primary" disableElevation>
                Novedades de Dictámenes
              </ColorButton>
          </Grid>}
          <Grid className={clases.itemStyleb} item xs={10} sm={10} md={4} lg={4} xl={4} container justifyContent="center">
            <AdvancedSearchPopup trigger = {AdvButton} onSearch= {props.onSearch} />
          </Grid>
          <Hidden only={['md', 'lg','xl']}>
            <Grid className={clases.conteStyleEscudo} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Headerp/>
            </Grid>
          </Hidden>
      </Grid>
      
    </div>
  );
}
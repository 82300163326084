function getTermFilterValue(field, fieldValue) {
  // We do this because if the value is a boolean value, we need to apply
  // our filter differently. We're also only storing the string representation
  // of the boolean value, so we need to convert it to a Boolean.

  // TODO We need better approach for boolean values
  if (fieldValue === "false" || fieldValue === "true") {
    return { [field]: fieldValue === "true" };
  }

  return { [`${field}.keyword`]: fieldValue };
}

function getTermFilter(filter) {
  if (filter.type === "any") {
    return [{
      terms: { [`${filter.field}.keyword`]: filter.values }
    }]
  } else if (filter.type === "all") {
    return filter.values.map(filterValue => ({
        term: getTermFilterValue(filter.field, filterValue)
      }));
  }
}

function getDateFilter(filter) {
  var first_value = filter.values[0];
  var second_value = filter.values[1] || filter.values[0]
  return {
    range: { "fecha": {
                "gte": `${first_value}-01-01`,
                "lte": `${second_value}-12-31`
            }}
  };
}

function getMonthFilter(filter) {
  var meses = filter.values.map(mes => {
    return {
      range: {
        "fecha": {
          "gte": `${mes}`,
          "lt": `${mes}||+1M/d`
        }
      }
    }
  });
  return {
    bool: {
      should: meses
    }
  }
}

export default function buildRequestFilter(filters, searchTerm) {
  var tomo_match = searchTerm.match(/tomo ([0-9]+)/i)
  var pagina_match = searchTerm.match(/(?:pagina|página)\s([0-9]+)/i)
  if (!filters) return;
  filters = filters.reduce((acc, filter) => {
    if (["voces", "organismo"].includes(filter.field)) {
      return acc.concat(getTermFilter(filter));
    }
    if (["anio"].includes(filter.field) && !(tomo_match || pagina_match)) {
      return [...acc, getDateFilter(filter)];
    }
    if (["mes"].includes(filter.field)) {
      return [...acc, getMonthFilter(filter)];
    }
    // Esto es para filtros hechos por rango. Ver github de ui-Search
    // if (["acres", "visitors"].includes(filter.field)) {
    //   return [...acc, getRangeFilter(filter)];
    // }
    return acc;
  }, []);
  if (filters.length < 1) return;
  return filters;
}

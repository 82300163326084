import buildStateFacets from "./buildStateFacets";
import { wordsToBeHighlighted, processResultText, removeDuplicates } from "./helpers/processResultText";

const GREEN_COLOR = "#2ED47A"
const YELLOW_COLOR = "#FFB946"

function buildTotalPages(resultsPerPage, totalResults) {
  if (!resultsPerPage) return 0;
  if (totalResults === 0) return 1;
  return Math.ceil(totalResults / resultsPerPage);
}

function buildTotalResults(hits) {
  return hits.total.value;
}

function isTomoOrPaginaSearch(searchTerm) {
  var tomo_match = searchTerm.match(/tomo ([0-9]+)/i)
  var pagina_match = searchTerm.match(/(?:pagina|página)\s([0-9]+)/i)
  if (tomo_match || pagina_match) {
    return true
  } else {
    return false
  }
}

function buildResults(hits, searchTerm) {
  const addEachKeyValueToObject = (acc, [key, value]) => ({
    ...acc,
    [key]: value
  });

  const toObject = (value, snippet) => {
    return { raw: value, ...(snippet && { snippet }) };
  };
  var highlight_words = wordsToBeHighlighted(searchTerm)


  return hits.map((record, index) => {
    record._source.id = record._id;
    record._source.index = record._index;
    record._source.score = record._score;
    record._source.color = (index % 2 === 0) ? YELLOW_COLOR : GREEN_COLOR
    if (record._index !== "historico") {
      record._source.voces = removeDuplicates(record._source.voces)

      var doctrina = record._source.attachments[1].attachment.content;
      record._source.doctrina = processResultText(doctrina, highlight_words, record._source.leyes, record._source.doctrinas_asociadas, record._source.decretos, record._source.fallos_asociados)
      // record._source.highlight = Object.values(record.highlight)[0];
    } else {
      record._source.voces = [""]
      //TODO cargar archivo de preview del pdf aca??
    }

    return Object.entries(record._source)
      .map(([fieldName, fieldValue]) => [
        fieldName,
        toObject(fieldValue)
      ])
      .reduce(addEachKeyValueToObject, {});
  });
}

function gotQuotesSearchResponse(maxScore, otherResponse) {
  if (!otherResponse || !maxScore) { return false }
  return (maxScore <= otherResponse.hits.max_score)
}
/*
  Converts an Elasticsearch response to new application state

  When implementing an onSearch Handler in Search UI, the handler needs to convert
  search results into a new application state that Search UI understands.

  For instance, Elasticsearch returns "hits" for search results. This maps to
  the "results" property in application state, which requires a specific format. So this
  file iterates through "hits" and reformats them to "results" that Search UI
  understands.

  We do similar things for facets and totals.
*/
export default function buildState(response, resultsPerPage, anioFilter, otherResponse = null) {
  const results = buildResults(response.hits.hits, response.searchTerm);
  const totalResults = buildTotalResults(response.hits);
  const totalPages = buildTotalPages(resultsPerPage, totalResults);
  const facets = buildStateFacets(response.aggregations);
  const emptyQuotesSearch = gotQuotesSearchResponse(response.hits.max_score, otherResponse)
  const tomoSearch = isTomoOrPaginaSearch(response.searchTerm)

  return {
    results,
    totalPages,
    totalResults,
    emptyQuotesSearch,
    tomoSearch,
    anioFilter,
    ...(facets && { facets })
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Typography, Card, CardContent } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Hidden } from "@material-ui/core";


pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export default class ViewMore extends React.Component {
    constructor(props) {
        super(props);

        this.state = { display: false, viewMoreText: this.props.viewMoreText, viewLessText: this.props.viewLessText, preview: this.props.preview };
    }

    setStatus() {
        let display = !this.state.display;
        this.setState({ display: display });
    }

    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar los props):
        if (this.props.preview !== prevProps.preview) {
            // var [primaryText, secondaryText] = getTrimText(this.props);
            this.setState({ preview: this.props.preview })
        }
    }

    render() {
        // console.log(this.state.preview);
        // const blob = new Blob([base64ToArrayBuffer(this.state.preview)], {type: "application/pdf"});
        // const a = document.createElement('a');
        // a.href = window.URL.createObjectURL(blob);
        // // a.target=`${file}.pdf`;
        // document.body.appendChild(a);

        let displayDoc;
        if (this.state.display) {
            displayDoc = (
                <div className="display-text-group">
                    <Hidden only={['xs', 'sm']}>
                        <Hidden only={['xl']}>
                            <Card sx={{ margin: 2 }}>
                                <CardContent>
                                    <div className="all-page-container">
                                    <Document file={{ data: base64ToArrayBuffer(this.state.preview) }}>
                                        <Page scale={0.75} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
                                    </Document>
                                        {/* <Document file={a.href} /> */}
                                    </div>
                                </CardContent>
                            </Card>
                            <div className="read-more-button"
                                onClick={this.setStatus.bind(this)}>
                                <Typography style={{ textAlign: "right" }} f variant="body2">
                                    {this.state.viewLessText + ""}
                                    <ExpandLessIcon fontSize="large" />
                                </Typography>
                            </div>
                        </Hidden>
                        <Hidden only={['lg', 'md']}>
                            <Card sx={{ margin: 2 }}>
                                <CardContent >
                                    <div className="all-page-container">
                                    <Document file={{ data: base64ToArrayBuffer(this.state.preview) }}>
                                        <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
                                    </Document>
                                        {/* <Document file={a.href} /> */}
                                    </div>
                                </CardContent>
                            </Card>
                            <div className="read-more-button"
                                onClick={this.setStatus.bind(this)}>
                                <Typography style={{ textAlign: "right" }} f variant="body2">
                                    {this.state.viewLessText + ""}
                                    <ExpandLessIcon fontSize="large" />
                                </Typography>
                            </div>
                        </Hidden>
                    </Hidden>
                    <Hidden only={['lg', 'xl', 'md']}>
                        <Hidden only={['xs']}>
                            <Card sx={{ margin: 2 }}>
                                <CardContent>
                                    <div className="all-page-container">
                                    <Document file={{ data: base64ToArrayBuffer(this.state.preview) }}>
                                        <Page scale={0.7} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
                                    </Document>

                                        {/* <Document file={a.href} /> */}
                                    </div>
                                </CardContent>
                            </Card>
                            <div className="read-more-button"
                                onClick={this.setStatus.bind(this)}>
                                <Typography style={{ textAlign: "right" }} f variant="body2">
                                    {this.state.viewLessText + ""}
                                    <ExpandLessIcon fontSize="large" />
                                </Typography>
                            </div>
                        </Hidden>
                        <Hidden only={['sm']}>
                            <Card sx={{ margin: 2 }}>
                                <CardContent>
                                    <div className="all-page-container">
                                    <Document file={{ data: base64ToArrayBuffer(this.state.preview) }}>
                                        <Page scale={0.4} pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
                                    </Document>

                                        {/* <Document file={a.href} /> */}
                                    </div>
                                </CardContent>
                            </Card>
                            <div className="read-more-button"
                                onClick={this.setStatus.bind(this)}>
                                <Typography style={{ textAlign: "right" }} f variant="body2">
                                    {this.state.viewLessText + ""}
                                    <ExpandLessIcon fontSize="large" />
                                </Typography>
                            </div>
                        </Hidden>
                    </Hidden>


                </div>
            );
        } else {
            displayDoc = (
                <div className="read-more-button"
                    onClick={this.setStatus.bind(this)}>
                    <Typography style={{ textAlign: "right" }} variant="body2">
                        {this.state.viewMoreText + " "}
                        <ExpandMoreIcon fontSize="large" />
                    </Typography>
                </div>
            );
        }

        return displayDoc;
    }
}

ViewMore.propTypes = {
    viewMoreText: PropTypes.string,
    viewLessText: PropTypes.string,
};

ViewMore.defaultProps = {
    viewMoreText: "read more",
    viewLessText: "read less",
};

import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import { Typography, Hidden } from '@material-ui/core';

import { appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";

const setDefaultStyle = {
  option: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
  singleValue: provided => {
    // Pulling out CSS that we don't want
    // eslint-disable-next-line no-unused-vars
    const { position, top, transform, maxWidth, ...rest } = provided;
    return { ...rest, lineHeight: 1,
            marginRight: 0,
            color: '#0976C2',
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "14pt",
         };
  },
  valueContainer: provided => ({ ...provided, paddingRight: 0 }),
  menu: provided => ({ ...provided,
                      color: '#097CC1',
                      fontFamily: "'Roboto', sans-serif",
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "14pt", }),
  control: provided => ({ ...provided, border: 0, backgroundColor: "none", boxShadow: "none", cursor: "pointer"}),
};


const setDefaultStyleP = {
  option: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
  singleValue: provided => {
    // Pulling out CSS that we don't want
    // eslint-disable-next-line no-unused-vars
    const { position, top, transform, maxWidth, ...rest } = provided;
    return { ...rest, lineHeight: 1,
            marginRight: 0,
            color: '#0976C2',
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontStyle: "normal",
            fontSize: "7.33pt",
            '@media  (max-width: 600px)': {
                fontSize: "7.33pt",
              },
              '@media  (min-width: 601px) and (max-width: 768px)': {
                fontSize: "7.33pt",
              },
         };
  },
  valueContainer: provided => ({ ...provided, paddingRight: 0 }),
  menu: provided => ({ ...provided,
                      color: '#097CC1',
                      fontFamily: "'Roboto', sans-serif",
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "14pt",  
                      '@media  (max-width: 800px)': {
                          fontSize: "8pt",
                        },}),
  control: provided => ({ ...provided, border: 0, backgroundColor: "none", boxShadow: "none", cursor: "pointer"}),
};

const wrapOption = option => ({ label: option, value: option });

function ResultsPerPage({
  className,
  onChange,
  options,
  value: selectedValue,
  ...rest
}) {
  let selectedOption = null;

  if (selectedValue) {
    selectedOption = wrapOption(selectedValue);

    if (!options.includes(selectedValue)) options = [selectedValue, ...options];
  }

  return (
    <div
      className={appendClassName("sui-results-per-page", className)}
      {...rest}
    >
       
    <Hidden only={['xs','sm']}>
      <Typography variant="h6">
        <div className="sui-results-per-page__label" style={{color: "#717171"}}>Mostrar</div>
      </Typography>
    </Hidden>
    {/*<Hidden only={['md','lg','xl']}>
      <Typography variant="caption">
        <strong>
        <div style={{width:"80px"}}>Items por página</div>
        </strong>
      </Typography>
  </Hidden>*/
    <Hidden only={['xs','sm']}>
      <Select
        className="sui-select sui-select--inline"
        classNamePrefix="sui-select"
        value={selectedOption}
        onChange={o => onChange(o.value)}
        options={options.map(wrapOption)}
        isSearchable={false}
        styles={setDefaultStyle}
      />
      </Hidden>
    /*  <Hidden only={['md', 'lg','xl']}>
      <Select
        className="sui-select sui-select--inline"
        classNamePrefix="sui-select"
        value={selectedOption}
        onChange={o => onChange(o.value)}
        options={options.map(wrapOption)}
        isSearchable={false}
        styles={setDefaultStyleP}
      />
      </Hidden>*/}
    </div>
  );
}

ResultsPerPage.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
  value: PropTypes.number
};

export default ResultsPerPage;

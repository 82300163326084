import React from "react";
import Popup from "reactjs-popup";
import { Paper, TextField, Button, Divider} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    display: 'flex', 
    flexFlow: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    fontSize: '15px', 
    fontWeight: '500', 
    fontStyle: 'normal', 
    fontFamily: "'Roboto', sans-serif", 
    color: '#192a3e' 
  },
  closeButton: {
    fontSize: '20px', 
    fontFamily: "sans-serif", 
    color: '#707683', 
    cursor: 'pointer' 
  },
  modal: {
    display: 'flex', 
    flexFlow:'column', 
    height: '400px'
  },
  column: {
    display:'flex', 
    flexFlow:'column', 
    justifyContent: 'space-between',
    height: 'inherit',
    color: '#707683',
  },
  paper: {
    border: 'solid 0px', 
    padding: '25px'
  },
  divider: {
    margin: '5px -25px'
  }
}));


export default function AdvancedSearchPopup(props) {
  const classes = useStyles();
  const [tomo, setTomo] = React.useState('')
  const [pagina, setPagina] = React.useState('')
  const [dictamen, setDictamen] = React.useState('')
  const [anio, setAnio] = React.useState('')
  const [tema, setTema] = React.useState('')

  const SubmitButton = withStyles((tema) => ({
    root: {
      color: '#FFF',
      backgroundColor: '#097CC1',
      borderRadius: '4px',
      fontSize: '12px',
      padding: '10px 55px',
      marginTop: '20px',
      '&:hover': {
        backgroundColor: '#097CC1',
        opacity: '0.9',
      },
    },
  }))(Button);

  const onClick = (e) => {
    e.preventDefault();
    let advSearch = `BusquedaAvanzada: tomo ${tomo}; pagina ${pagina}; dictamen ${dictamen}; fecha ${anio}; tema ${tema}`
    props.onSearch(advSearch)
}

  return (
    <Popup trigger={props.trigger} modal contentStyle={{maxWidth: '400px', padding: '0', background: 'none', border: '0'}} >
    {close => (
      <Paper elevation={4} className={classes.paper}>
      
      <form onSubmit={(e) => { onClick(e) }}>
        <div className={classes.modal}>
          <div className={classes.heading} >
            Búsqueda Avanzada
            <a href onClick={close} className={classes.closeButton}> X </a>
          </div>
          <Divider className={classes.divider}/>
          
          <div className={classes.column} >
            <TextField onChange={event => setTomo(event.target.value.trim())} style={{display:"flex"}} id="as-tomo" label="Tomo" />
            <TextField onChange={event => setPagina(event.target.value.trim())} style={{display:"flex"}} id="as-pagina" label="Página" />
            <TextField onChange={event => setDictamen(event.target.value.trim())} style={{display:"flex"}} id="as-dictamen" label="Nº de Dictamen" />
            <TextField type="number" onChange={event => setAnio(event.target.value)} style={{display:"flex"}} id="as-anio" label="Año (aaaa)"/>
            <TextField onChange={event => setTema(event.target.value.trim())} style={{display:"flex"}} id="as-tema" label="Tema / Palabras en la doctrina" />
          </div>

          <SubmitButton type='submit'  size="medium" color="primary">
            Ejecutar búsqueda avanzada
          </SubmitButton>
          </div>
        </form>
      
      
      </Paper>
    )}
    </Popup>
  );
}

import React from "react";
import axios from 'axios';
import { Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

export const setToken = (token) => {
    // set token in local storage
    sessionStorage.setItem('react-fastapi-token', token);
}

export const fetchToken = (token) => {
    // fetch token
    return sessionStorage.getItem('react-fastapi-token');
}

export const unsetToken = (token) => {
    return sessionStorage.removeItem('react-fastapi-token');
}

export function RequireToken({children}) {

    useEffect(() => {
        axios({
            method: 'get',
            url: `${window._env_.REACT_APP_API_URL}/users/me`,
            headers: {"Access-Control-Allow-Origin": "*", "Content-Type":"application/json", "Authorization": `Bearer ${auth}`}
        }).then(() => {},
        (error) => {
            if (error.response.status === 401) {
                unsetToken();
            }
        })
    });
    
    let auth = fetchToken();
    let location = useLocation();
    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
}
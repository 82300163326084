import React, {useState}  from 'react';
import { Header } from './Header';
import {  MuiThemeProvider  } from '@material-ui/core/styles';
import theme from './ConfigTheme';
import { Footer } from './Footer';
import { Box,makeStyles, Container ,Hidden} from '@material-ui/core'; 

const temaModi = makeStyles(theme =>(
  {
    titleL: {
      flexGrow:1,
      color:'#097CC1',
      backgroundColor:'#097CC1',
    },
    titleP: {
      flexGrow:1,
      color:'#097CC1',
      backgroundColor:'#097CC1',
      padding: '38px',
    },
    img:{
      height: 71,
      width: 213,
      '@media  (max-width: 600px)': {
        height: 35.5,
        width: 106.5,
      },
      '@media  (min-width: 600px) and (max-width: 768px)': {
        height: 71,
        width: 213,
      },
      '@media  (min-width: 769px) and (max-width: 1200px)': {
        height: 71,
        width: 213,
      },
    },
    paper: {
      margin: theme.spacing(8),
      padding: theme.spacing(8),
      textAlign: 'center',
    },


}))

export default function Layout(props) {
  const clases = temaModi();  
  const [dondeesta, setdonde]=useState(false)

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <Header dondeesta={dondeesta}/> 
          <Hidden only={['md', 'lg','xl']}>
            <Box className={clases.titleP} border = {3}>       
            </Box>
              <Container>
              {props.children}
              </Container>     
            </Hidden> 

            <Hidden only={['xs','sm']}>
              <Box className={clases.titleL} border = {3}>       
              </Box>
              <Container>
                {props.children}
              </Container>
            </Hidden> 
            <Hidden only={['xs','sm']}>
              <Footer/>
          </Hidden> 
         
        </MuiThemeProvider>
      </React.Fragment>
  )
  }

import PropTypes from "prop-types";
import React from "react";
import { Pagination } from '@material-ui/lab';
import { Container, Hidden, Typography } from "@material-ui/core";





function Paging({
  className,
  current,
  resultsPerPage,
  onChange,
  totalPages,
  ...rest
}) {
  return (
    <>
    <Hidden only={['xs','sm']}>
    <Container style = {{display:"flex", alignItems:"center"}}>
      <Typography variant="subtitle1">
        <div style = {{paddingRight:"15px"}}>
          Página{" "}
          <strong>
            {current}
          </strong>{" "}
          de <strong>{totalPages}</strong>
          </div>
        </Typography>
        <Pagination     
          page={current}
          onChange={(event,page) => { onChange(page); window.scrollTo(0, 0) }}
          count={totalPages}
          // siblingCount={0} boundaryCount={0}
          className={"sui-paging"}
          size={"large"}
          showFirstButton
          showLastButton
          color={"secondary"}
          {...rest}
        />
    </Container>
  </Hidden>
   <Hidden only={['md','lg','xl']}>
    <Container style = {{display:"flex", alignItems:"center"}}>
      <Typography variant="subtitle1">
          <div style = {{paddingRight:"15px"}}>
            Página{" "}
            <strong>
              {current}
            </strong>{" "}
            de <strong>{totalPages}</strong>
            </div>
        </Typography>
        <Pagination
          page={current}
          onChange={(event,page) => { onChange(page); window.scrollTo(0, 0) }}
          count={totalPages}
          // siblingCount={0} boundaryCount={0}
          className={"sui-paging"}
          size={"large"}
          showFirstButton
          showLastButton
          color={"secondary"}
          {...rest}
        />
    </Container>
</Hidden>
</>
  );
}

Paging.propTypes = {
  current: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default Paging;

import React, { useState, FC, useCallback } from 'react';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Hidden } from '@material-ui/core';
import axios from 'axios';

const RecaptchaLink = (params) => {
    const id = params.id;
    const type = params.type;
    const historical = params.historical;
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');

    const clickHandler = useCallback(async () => {
        let w = window.open();
        if (!executeRecaptcha) {
        return;
        }

        const result = await executeRecaptcha(type);
        setToken(result);
        const response = await axios({
            method: 'post',
            url: `${window._env_.REACT_APP_API_URL}/confirmToken`,
            params: {'token': result, 'id': id, 'type': type, 'historical': historical},
            // responseType: 'blob' //Force to receive data in a Blob Format
        });
        if (response.status == 200) {
            let fileUrl = `${window._env_.REACT_APP_API_URL}${response.data.file}`
            w.location = fileUrl;
            // window.open(fileUrl, "_blank")            
        }
    }, []);


    return (
        <div>
            <Hidden only={['xs','sm']}>
                <Button variant="outlined" color="primary" size="small" onClick={clickHandler}>
                    { params.label }
                </Button>
            </Hidden>
            <Hidden only={['md', 'lg','xl']}>
                <Button variant="contained" color="secondary" size="small" onClick={clickHandler}>
                    { params.label }
                </Button>
            </Hidden>
        </div>
    );
};

export default RecaptchaLink;


import PropTypes from "prop-types";
import React from "react";
import { Icon } from '@iconify/react';
import { Typography } from '@material-ui/core';
import { FacetValue } from "@elastic/react-search-ui-views/lib/types";
import { getFilterValueDisplay, appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";
import calendarWeekend from '@iconify/icons-mdi/calendar-weekend';
import chevronSort from '@iconify/icons-carbon/chevron-sort';



function DateCheckboxFacet({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder
}) {
  return (
    <fieldset className={appendClassName("sui-facet", className)}>
      <legend className="sui-facet__title">
      <Typography variant="h6" style={{display: "flex", alignItems: "center", textTransform: "none"}}>
      <Icon icon={calendarWeekend} style={{color: "#C2CFE0", fontSize: "19.5px", marginRight: "5px"}}/> <span id={`${label}-label`}>{label}</span>
      </Typography>
      </legend>

      <div className="sui-multi-checkbox-facet">
        {options.length < 1 && <div>No se enconraron coincidencias</div>}
        {options.map(option => {
          const checked = option.selected;
          var fecha = new Date(option.value);
          var valor = `${fecha.toLocaleString('default', { month: 'long', timeZone: 'UTC' })} - ${fecha.getFullYear()}`;
          return (
            <Typography variant="h4" >
            <label
              key={`${getFilterValueDisplay(option.value)}`}
              htmlFor={`example_facet_${label}${getFilterValueDisplay(
                option.value
              )}`}
              className="sui-multi-checkbox-facet__option-label"
            >
              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                <input
                  id={`example_facet_${label}${getFilterValueDisplay(
                    option.value
                  )}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={checked}
                  onChange={() =>
                    checked ? onRemove(option.value) : onSelect(option.value)
                  }
                  style={{transform:"scale(1.1)"}}
                />
                <span className="sui-multi-checkbox-facet__input-text">
                  {getFilterValueDisplay(valor)}
                </span>
              </div>
              <span className="sui-multi-checkbox-facet__option-count">
              <Typography variant="caption" color={checked ? "secondary" : "primary"}>
                {option.count.toLocaleString("es")}
              </Typography>
              </span>
            </label>
            </Typography>
          );
        })}
      </div>

      {showMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onMoreClick}
          aria-label="Mostrar más opciones"
        >
        <Typography variant="h5"style={{display: "flex", alignItems: "center"}} >
          <Icon icon={chevronSort} style={{margin: 5}} focusable="true"/> Mostrar más
        </Typography>
        </button>
      )}
    </fieldset>
  );
}

DateCheckboxFacet.propTypes = {
  label: PropTypes.string.isRequired,
  onMoreClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(FacetValue).isRequired,
  showMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  showSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string
};

export default DateCheckboxFacet;

import PropTypes from "prop-types";
import React from "react";
import { Grid , Typography} from '@material-ui/core';
import { FacetValue } from "@elastic/react-search-ui-views/lib/types";
import { appendClassName } from "@elastic/react-search-ui-views/lib/view-helpers";
import _ from 'lodash';
import Rangofecha from "../components/Rangofecha.js";
import Rangofechah from "../components/Rangofechah.js";

const minimumYear = 1942;

function reconfigureValueAndMarks(anioFilter, marks) {
  var result = marks
  var newMarks = anioFilter.map(function(o) {
    return { value: parseInt(o), label: ""}
  })
  result = _.sortBy((_.uniqBy(result.concat(newMarks), 'value')),'value')
  return result

}


function SliderRangeSelectFacetP({ className, label, onRemove, onSelect, options, onChange, anioFilter,mini }) {
 
  if (anioFilter.length>0 && mini.length>0)
  {
    if (_.isEqual(mini, anioFilter) )
    {
      anioFilter=[];
    }
  }
  var value = options.filter(o => o.selected).map(o => o.value);
  var marks = options.map(function(o) {
    return { value: parseInt(o.value), label: ""}
  })

  if (!_.isEqual(value, anioFilter) && (anioFilter.length > 0)) {
    marks = reconfigureValueAndMarks(anioFilter, marks)
    value = anioFilter
  }

  const maximum = marks[marks.length - 1].value
  const minimum = (maximum > minimumYear) ?
                    Math.max(marks[0].value, minimumYear) :
                    marks[0].value;
  const defaultValues = [minimum,maximum]

  if (value[0] < marks[0]) {value[0] = marks[0]}
  if (value[1] > marks[marks.length - 1]) {value[1] = marks[marks.length - 1]}

  if (!value.length > 0) {
    marks = reconfigureValueAndMarks(defaultValues, marks)
    value = defaultValues
  }

  if ((value.length === 1) &&  value [0]!= defaultValues[0]) {
    value [1]= value [0];
  }

  if ((value.length === 1) &&  value [1]!= defaultValues[1]) {
    value [1]= value [0];
  }
// Agregado sh 15/02/2024
  if (anioFilter.length===0 && defaultValues.length>0)
  {
    anioFilter=defaultValues;
    marks = reconfigureValueAndMarks(anioFilter, marks)
    value = anioFilter
  }



  return (

    <div className={appendClassName("sui-facet", className)}>
      <div>
      <form  noValidate autoComplete="off">
        <Grid container>
          <Grid  item xs={12} sm={12} container alignContent="flex-start" spacing={4}>
                <Grid item xs={6} sm={6}> 
                    <Typography>Desde:</Typography>
                    <Rangofecha value={value} marks={marks} addFilter={onSelect} onChange={onChange} label={label} mini={mini}>

                    </Rangofecha>
                </Grid>
               
                <Grid item xs={6} sm={6}> 
                <Typography>Hasta:</Typography>
                    <Rangofechah value={value} marks={marks} addFilter={onSelect} onChange={onChange} label={label} mini={mini}>

                    </Rangofechah>
                </Grid>
               
          </Grid>
        </Grid>
        </form>    
          
      </div>
    </div>
  );
}
SliderRangeSelectFacetP.propTypes = {
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(FacetValue).isRequired,
  className: PropTypes.string
};

export default SliderRangeSelectFacetP;

import React from 'react';
import {
    ErrorBoundary,
    Facet,
    SearchProvider,
    WithSearch,
    SearchBox,
    Results,
    PagingInfo,
    ResultsPerPage,
    Paging,
    Sorting
} from "@elastic/react-search-ui";

import {
    ResultAdminView, PagingView, PagingInfoView, MultiCheckboxFacet,
    ResultsPerPageView, SortingView, InputView, DateCheckboxFacet
} from './views'
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { LinearProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";
import buildState from "./buildState";
import AddDictamenComponent from './components/addDictamenComponent';
import addDictamen from './helpers/addDictamen';

import { fetchToken, RequireToken, unsetToken } from './components/auth';
import Logout from './components/logout';

var emptyQuotesSearch = false;
var tomoSearch = false;
var anioFilter = [];

var sidebarShadow = {
    paddingLeft: "24px !important",
    boxShadow: "3px 0 2px -2px rgba(0,0,0,0.2)",
    padding: "32px 32px 0 0"
}

const config = {
    searching: "",
    initialState: {
        sortDirection: "desc",
        sortField: "fecha",
        resultsPerPage: 10,
    },
    debug: false,
    hasA11yNotifications: true,
    onResultClick: () => {
        /* Not implemented */
    },
    onAutocompleteResultClick: () => {
        /* Not implemented */
    },
    onAutocomplete: async ({ searchTerm }) => {
        // this.searching = searchTerm;
        // const requestBody = buildRequest({ searchTerm });
        // const json = await runRequest(requestBody);
        // const state = buildState(json);
        // return {
        //   autocompletedResults: state.results
        // };
        return {}
    },
    onSearch: async state => {
        // if (state.searchTerm === "") { return config.initialState }
        emptyQuotesSearch = false
        tomoSearch = false
        anioFilter = [];
        const { resultsPerPage } = state;
        const requestBody = buildRequest(state);
        anioFilter = requestBody.anioFilter
        // Note that this could be optimized by running all of these requests
        // at the same time. Kept simple here for clarity.
        const responseJson = await runRequest(requestBody);
        const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
            responseJson,
            state,
            ["organismo", "mes"]
        );
        if (state.searchTerm.includes('"')) {
            const requestBodyWithoutQuotes = buildRequest(state, true);
            const responseJsonWithoutQuotes = await runRequest(requestBodyWithoutQuotes);
            const newState = buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage,
                anioFilter, responseJsonWithoutQuotes);
            emptyQuotesSearch = newState.emptyQuotesSearch
            tomoSearch = newState.tomoSearch
            return newState
        }
        else {
            const newState = buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage, anioFilter);
            tomoSearch = newState.tomoSearch
            return newState;
        }
    }
};

export default function Admin() {
    return (
        <SearchProvider config={config}>
            <WithSearch mapContextToProps={({ wasSearched, isLoading, tomoSearch, setSearchTerm }) => ({ wasSearched, isLoading, tomoSearch, setSearchTerm })}>
                {({ wasSearched, isLoading, tomoSearch, setSearchTerm }) => (
                    <div className="App">
        <RequireToken>
                        <ErrorBoundary>
                            <div style={{ background: (wasSearched ? "#F5F6F8" : "#FFFFFF") }}>
                                <Logout/>
                                {!wasSearched &&
                                    <div className="body-home">
                                        <div style={{ margin: "50px 10% 0 10%", alignSelf: 'center' }}>
                                            <AddDictamenComponent onSearch={setSearchTerm} onSubmit={addDictamen}/>
                                        </div>
                                        <SearchBox
                                            className="sui-search-box_two"
                                            view={InputView}
                                            inputView={({ getInputProps, getButtonProps }) => (
                                                <>
                                                    <div className="sui-search-box__wrapper_two">
                                                        <Typography variant="h6">
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: "Ingresá el texto de búsqueda. Ej: tomo 251 pagina 787 / ley 26076 / deuda externa"
                                                                })}
                                                            />
                                                        </Typography>
                                                    </div>
                                                    <input
                                                        {...getButtonProps({
                                                            value: "Buscar"
                                                        })}
                                                    />

                                                </>
                                            )}
                                        />
                                    </div>
                                }
                                {wasSearched &&
                                    <Layout
                                        header={<div className="header-base-layout">
                                            <div style={{ margin: "50px 10% 0 10%", alignSelf: 'center' }}>
                                                <AddDictamenComponent onSearch={setSearchTerm} onSubmit={addDictamen}/>
                                            </div>
                                            <SearchBox
                                                view={InputView}
                                                inputView={({ getInputProps, getButtonProps }) => (
                                                    <>
                                                        <div className="sui-search-box__wrapper">
                                                            <Typography variant="h6">
                                                                <input
                                                                    {...getInputProps({
                                                                        placeholder: "Ingresá el texto de búsqueda. Ej: tomo 251 pagina 787 / ley 26076 / deuda externa"
                                                                    })}
                                                                />
                                                            </Typography>
                                                        </div>
                                                        <input
                                                            {...getButtonProps({
                                                                value: "Buscar"
                                                            })}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        }
                                        sideContent={
                                            <div style={wasSearched ? sidebarShadow : {}}>
                                                {wasSearched && (
                                                    <Sorting
                                                        label={"Ordenar por:"}
                                                        sortOptions={[
                                                            {
                                                                name: "Relevancia",
                                                                value: "",
                                                                direction: ""
                                                            },
                                                            {
                                                                name: "Más antiguos",
                                                                value: "fecha",
                                                                direction: "asc"
                                                            },
                                                            {
                                                                name: "Más recientes",
                                                                value: "fecha",
                                                                direction: "desc"
                                                            }
                                                        ]}
                                                        view={SortingView}
                                                    />
                                                )}
                                                {/* {!tomoSearch && <Facet mapContextToProps={context => {
                                                    if (!context.facets.anio) return context;
                                                    return {
                                                        ...context,
                                                        facets: {
                                                            ...(context.facets || {}),
                                                            anio: context.facets.anio.map(s => ({
                                                                ...s,
                                                                data: s.data.sort((a, b) => {
                                                                    if (a.value > b.value) return 1;
                                                                    if (a.value < b.value) return -1;
                                                                    return 0;
                                                                })
                                                            }))
                                                        }
                                                    };
                                                }}
                                                    field="anio" label="Fecha" show="30" view={props =>
                                                        SliderRangeSelectFacet({
                                                            ...props,
                                                            anioFilter: anioFilter
                                                        })} />} */}
                                                {!tomoSearch && <Facet
                                                    field="mes"
                                                    label="Fecha"
                                                    filterType="all"
                                                    isFilterable={true}
                                                    view={DateCheckboxFacet}
                                                    searchPlaceholder="Filtrar por mes y año de publicación"
                                                />}
                                                <Facet
                                                    field="organismo"
                                                    label="Organismo"
                                                    filterType="any"
                                                    isFilterable={true}
                                                    view={MultiCheckboxFacet}
                                                    searchPlaceholder="Filtrar por Organismo"
                                                />
                                                <Facet
                                                    field="voces"
                                                    label="Voces"
                                                    filterType="all"
                                                    isFilterable={true}
                                                    view={MultiCheckboxFacet}
                                                    searchPlaceholder="Filtrar por Voces"
                                                />
                                            </div>
                                        }
                                        bodyContent={
                                            <div>
                                                {isLoading && <div className="loader"> <LinearProgress color="secondary" /> </div>}
                                                {!isLoading && (
                                                    <Results
                                                        titleField="voces"
                                                        resultView={props => ResultAdminView({...props, onSearch: setSearchTerm})}
                                                        // onSearch={setSearchTerm}
                                                    // urlField="nps_link"
                                                    // shouldTrackClickThrough={true}
                                                    />
                                                )}
                                            </div>
                                        }
                                        bodyHeader={
                                            <React.Fragment>
                                                {wasSearched && <PagingInfo
                                                    view={props =>
                                                        PagingInfoView({
                                                            ...props,
                                                            showQuotesSearchText: emptyQuotesSearch
                                                        })} />}
                                                {wasSearched && <ResultsPerPage view={ResultsPerPageView} options={[10, 25, 50]} />}
                                            </React.Fragment>
                                        }
                                        bodyFooter={<div>{!isLoading && (<Paging view={PagingView} />)}</div>}
                                    />}
                            </div>
                        </ErrorBoundary>
                        </RequireToken>
                    </div>
                )}
            </WithSearch>
        </SearchProvider>
    )
}
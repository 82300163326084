import React, { useState, useCallback } from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios';
import AddDictamenPopup from './addDictamenPopup';
import { fetchToken, RequireToken } from '../components/auth';

const ModifyDictamenComponent = (params) => {
    const [loaded, setLoaded] = React.useState(false);
    const [docData, setDocData] = React.useState('');
    const closeModal = () => setLoaded(false);
    const id = params.id;

    const clickHandler = useCallback(async () => {
        let token = fetchToken();
        if (token) {
            const response = await axios({
                method: 'get',
                url: `${window._env_.REACT_APP_API_URL}/admin/dictamen/${params.id}`,
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
            });
            // response.data.fecha = "30-05-2021";
            await setDocData(response.data);
        }
        setLoaded(true);
    }, []);

    /*     const ModifyButton = () => {
            return (
            )
        }
     */
    return (
        <div>
            <RequireToken>
                <Button variant="outlined" color="error" size="small" onClick={clickHandler}>
                    {params.label}
                </Button>
                <AddDictamenPopup open={loaded}/*trigger={ModifyButton}*/
                    onClose={closeModal}
                    onSubmit={params.onSubmit}
                    onSearch={params.onSearch}
                    buttonName="Cargar Modificaciones"
                    header="Dictamen"
                    id={params.id}
                    titulo={docData.titulo}
                    tomo={docData.tomo}
                    pagina={docData.pagina}
                    numero={docData.numero}
                    expediente={docData.expediente_numero}
                    organismo={docData.organismo}
                    fecha={params.fecha}
                    dictamen={docData.dictamen}
                    doctrina={docData.doctrina} />
            </RequireToken>
        </div>
    );
};

export default ModifyDictamenComponent;
import React from "react";
import { Button } from "@material-ui/core";
import { fetchToken } from "./auth.js";
import { useNavigate } from "react-router";
import axios from 'axios';
import { unsetToken } from "./auth.js";

export default function Logout() {
    let navigate = useNavigate();
    const logout = () => {
        const token = fetchToken();
        unsetToken();
        axios({
            method: 'post',
            url: `${window._env_.REACT_APP_API_URL}/auth/logout`,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
        })
            .then(function (response) {
                navigate("/");
            })
            .catch(function (error) {
                console.log(error, 'error');
                navigate("/");
            })
    }

    return (
        <>
            <Button color="contained" onClick={logout}>Salir</Button>
        </>
    )
}
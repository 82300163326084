import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PtnPopup from './ptn-popup';
import { Icon } from '@iconify/react';
import fileDownload from '@iconify/icons-mdi/file-download';
import RecaptchaLink from '../components/recaptcha';

export default class DoctrinaContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let displayText;
    const componentes = this.props.text.split(/(<referencias[^<]*<\/referencias>)/);
    displayText = componentes.map((comp) => {
      let re = RegExp('<referencias id="(.+)">(.+)<\/referencias>');
      let referencias = re.exec(comp)
      if (referencias) {
        return (
          <PtnPopup
            trigger={<a href=""> {referencias[2]} </a>}
            position="top"
            on="hover"
            closeOnDocumentClick
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ padding: '10px', border: '5px', boxShadow: '2px 2px rgba(0,0,0,0.2)', background: '#FFF'}}
            arrow={false}
          >
            <Grid container column="true" >
              <Grid item xs={12} container direction="column" alignContent="center" alignItems="center" justify="flex-start" spacing={2}>
                <Grid item>
                  <Icon icon={fileDownload} style={{ color: "#C2CFE0", width: 32, height: 32 }} />
                </Grid>
                <Grid item>
                  <RecaptchaLink type="doctrina" id={referencias[1]} label="Ver Doctrina" />
                </Grid>
                <Grid item>
                  <RecaptchaLink type="dictamen" id={referencias[1]} label="Ver Dictamen" />
                </Grid>
              </Grid>
            </Grid>
          </PtnPopup>
        );
      } else {
        return (
          <span className="displayed-text" dangerouslySetInnerHTML={{ __html: `${comp}` }} />
        );
      }
    });
    return displayText;
  }
}

DoctrinaContent.propTypes = {
  text: PropTypes.string.isRequired,
};

import React from "react";
import Popup from "reactjs-popup";
import { Paper, TextField, Divider, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontFamily: "'Poppins', sans-serif",
    color: '#192a3e'
  },
  closeButton: {
    fontSize: '20px',
    fontFamily: "sans-serif",
    color: '#707683',
    cursor: 'pointer'
  },
  modal: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  column: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    height: 'inherit',
    color: '#707683',
  },
  paper: {
    maxHeight: '300px',
    // overflow: 'auto',
    border: 'solid 0px',
    padding: '25px'
  },
  paperContent: {
    maxHeight: '450px',
    overflow: 'auto',
    border: 'solid 0px',
    padding: '25px'
  },
  divider: {
    margin: '5px -25px'
  },
  input: {
    display: 'none',
  }
}));


export default function AvisoHistoricosPopup(props) {
  const classes = useStyles();

  return (
    <Popup open={props.open} trigger={props.trigger} modal contentStyle={{background: 'none', border: '0'}} onClose={props.onClose} closeOnDocumentClick={true} closeOnEscape={true}>
      {close => (
        <Paper elevation={4} className="result-paper" style={{/*borderLeft: 'solid 5px', borderColor: "#8B5AF8",*/ padding: '25px' }} /* className={classes.paper} */> 
          <div className="help-modal">
            <button className="close" onClick={close} />
            <h1> Novedades </h1>
            <Divider className={classes.divider} />
            <Paper elevation={0} className={classes.paperContent}>
              <Typography>Ya están disponibles para su búsqueda los dictámenes históricos de la Procuración.</Typography>
              <Typography><a href="https://www.argentina.gob.ar/noticias/dictamenes-historicos-ahora-tambien-accesibles-desde-el-buscador">Más información...</a></Typography>
            </Paper>
          </div>
        </Paper>
      )}
    </Popup>
  );
}

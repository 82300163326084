import React from "react";
import { withSearch } from "@elastic/react-search-ui";
import { Button } from "@material-ui/core";

function ClearFilters({ filters, clearFilters }) {
  return (
    <div>
        <Button style={{fontSize:"10px"}} onClick={() => clearFilters()} color="secondary">
            Limpiar Filtros
        </Button>
    </div>
  );
}

export default withSearch(({ filters, clearFilters }) => ({
  filters,
  clearFilters
}))(ClearFilters);
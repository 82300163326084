import React, { useState } from "react";
import * as Yup from 'yup';
import { setToken, fetchToken } from "./auth.js";
import { useNavigate } from "react-router";
import { stringify } from 'qs';
import axios from 'axios';
import { TextField, Typography, Container, Box, Checkbox, FormControlLabel, Button, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import { useFormik, Form, FormikProvider } from 'formik';

export default function Login() {
    let navigate = useNavigate();
    const [message, setMessage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorA, setErrorA] = useState('');

    const LoginSchema = Yup.object().shape({
        usuario: Yup.string().required('Usuario Obligatorio'),
        password: Yup.string().required('Contraseña Obligatoria'),
    });

    const formik = useFormik({
        initialValues: {
            usuario: '',
            password: '',
            remember: true,
        },
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true);
            axios({
                url: `${window._env_.REACT_APP_API_URL}/auth/login`,
                method: "POST",
                data: stringify({ 'username': values.usuario + "@ptn.gob.ar", 'password': values.password }),
            }).then((response) => {
                switch (response.statusText) {
                    case "OK":
                        setMessage(false);
                        setErrorA("");
                        setToken(response.data.access_token);
                        navigate("/admin");
                        break;
                    default:
                        setMessage(true);
                        setErrorA("Error Inesperado");
                        setSubmitting(false);
                        break;
                }
                setLoading(false);
            }
            ).catch(error => {
                console.log(error)
                switch (error.message) {
                    case "Request failed with status code 400":
                        setMessage(true);
                        setErrorA("Usuario o Contraseña Erróneos");
                        setSubmitting(false);
                        break;
                    case "Network Error":
                        setMessage(true);
                        setErrorA("Error - Comuníquese con el Administrador");
                        setSubmitting(false);
                        break;
                    default:
                        setMessage(true);
                        setErrorA("Usuario o Contraseña Erróneos");
                        setSubmitting(false);
                        break;
                }
                setLoading(false);
            });
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <>
            <div className="App" >
                {
                    fetchToken()
                        ? (
                            <p>You are logged in!</p>
                        )
                        : (
                            <>
                                <div style={{ height: 20 }} />
                                <FormikProvider value={formik}>
                                    <Container component="main" maxWidth="xs">
                                        <Box
                                            sx={{
                                                marginTop: 8,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography component="h1" variant="h1">
                                                Publicación de dictámenes
                                            </Typography>
                                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                                <Box direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                                    {message ? <Alert variant="outlined" severity="error">{errorA}</Alert> : ""}
                                                </Box>
                                                <Box spacing={3}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete="username"
                                                        type="text"
                                                        label="Nombre de Usuario"
                                                        {...getFieldProps('usuario')}
                                                        error={Boolean(touched.usuario && errors.usuario)}
                                                        helperText={touched.usuario && errors.usuario}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        autoComplete="current-password"
                                                        type='password'
                                                        label="Contraseña"
                                                        {...getFieldProps('password')}
                                                        error={Boolean(touched.password && errors.password)}
                                                        helperText={touched.password && errors.password}
                                                    />
                                                </Box>
                                                <Box direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                                                    <FormControlLabel
                                                        control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                                                        label="Recordar Contraseña"
                                                    />
                                                </Box>
                                                {isLoading && <div className="loader"> <LinearProgress color="secondary" /> </div>}
                                                {!isLoading &&
                                                    <Button fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                                                        Ingresar
                                                    </Button>
                                                }
                                            </Form>
                                        </Box>
                                    </Container>
                                </FormikProvider>
                            </>
                        )
                }
            </div>
        </>
    )
}